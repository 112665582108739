import { css } from 'styled-components';

export const ELEVATIONS_MAP = {
  base: css`
    z-index: 0;
  `,
  tiny: css`
    box-shadow: ${props => props.theme.shadows.tiny};
    z-index: 50;
  `,
  small: css`
    box-shadow: ${props => props.theme.shadows.small};
    z-index: 100;
  `,
  soft: css`
    box-shadow: ${props => props.theme.shadows.soft};
    z-index: 100;
  `,
  medium: css`
    box-shadow: ${props => props.theme.shadows.medium};
    z-index: 150;
  `,
  large: css`
    box-shadow: ${props => props.theme.shadows.large};
    z-index: 200;
  `,
};
export const ELEVATION_OPTIONS = Object.keys(ELEVATIONS_MAP);

export const POSITION_OPTIONS = ['static', 'relative', 'absolute'];

export const SIZES_MAP = {
  tiny: '220px',
  small: '400px',
  medium: '550px',
  large: '700px',
  huge: '850px',
  xhuge: '1000px',
  custom: props => props.width,
};
export const SIZE_OPTIONS = Object.keys(SIZES_MAP);
