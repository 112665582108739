import { css } from 'styled-components';

const iconGlyphStyle = css`
  align-items: center;
  font-family: ${props => props.theme.fontFamilies.icons};
  font-feature-settings: 'liga';
  font-style: normal;
  font-weight: normal;
  justify-content: center;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-flex;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
`;

export default iconGlyphStyle;
