import { position as polishedPos } from 'polished';

const getPosition = ({ position = 'absolute', top, right, bottom, left }) => {
  const values = [top, right, bottom, left];
  return polishedPos(
    position,
    ...values.map(value => {
      switch (typeof value) {
        case 'string':
          return value;
        case 'number':
          return `${value}px`;
        default:
          return null;
      }
    }),
  );
};

export default getPosition;
