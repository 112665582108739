import styled from 'styled-components';
import transition from 'src/mixins/css-transitions';

import HiddenRadio from './HiddenRadio';

const animationSettings = property => ({
  duration: '200ms',
  property,
});

const ProofFieldLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;

  svg {
    #flag,
    #proof-icon {
      transition: ${transition(animationSettings('fill'))};
    }

    #flag {
      fill: ${props => props.theme.colors.gray['200']};
    }

    #proof-icon {
      fill: ${props => props.theme.colors.gray['600']};
    }
  }

  &:hover {
    svg {
      #flag {
        fill: ${props => props.theme.colors.gray['300']};
      }

      #proof-icon {
        fill: ${props => props.theme.colors.black};
      }
    }
  }

  ${HiddenRadio}:checked ~ & {
    svg {
      #flag {
        fill: ${props => props.theme.colors.primary.default};
      }

      #proof-icon {
        fill: ${props => props.theme.colors.white};
      }
    }

    &:hover {
      svg {
        #flag {
          fill: ${props => props.theme.colors.primary.fallback};
        }
      }
    }
  }
`;

export default ProofFieldLabel;
