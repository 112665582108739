import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { colorProperty, textStyleProperty } from 'src/mixins/common-properties';

const Anchor = styled.a.attrs(({ onClick }) => ({
  role: onClick ? 'button' : 'link',
}))`
  color: ${colorProperty};
  cursor: pointer;
  text-decoration: none;
  ${textStyleProperty};

  &:hover {
    color: ${props => lighten(0.15, colorProperty(props))};
    text-decoration: underline;
  }

  &:active {
    color: ${props => darken(0.15, colorProperty(props))};
  }
`;

export default Anchor;
