import React from 'react';
import PropTypes from 'prop-types';
import { oneOrMoreOfType } from 'src/utils/custom-prop-types';

import Container from '../styles/CardGridContainer';

const CardGrid = props => <Container {...props} />;

CardGrid.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: oneOrMoreOfType(PropTypes.element).isRequired,
};

CardGrid.defaultProps = {
  id: null,
  className: null,
};

export default CardGrid;
