import styled from 'styled-components';
import { textAlign } from 'styled-system';
import { captionTextStyle } from 'src/mixins/text-styles';
import { ARROW_SIZE } from '../constants';
import {
  colorSchemeVariants,
  bgColorSchemeVariants,
  directionVariants,
} from './variants';

const TooltipContent = styled.span`
  ${captionTextStyle};
  ${directionVariants};
  ${textAlign};
  ${({ maxWidth }) =>
    maxWidth ? `max-width: ${maxWidth};` : 'white-space: nowrap;'};
  background-color: ${bgColorSchemeVariants};
  border-radius: 4px;
  box-shadow: ${props => props.theme.shadows.medium};
  color: ${colorSchemeVariants};
  display: none;
  padding: ${props => props.theme.space[2]}px ${props => props.theme.space[3]}px;
  position: absolute;
  z-index: 1;

  ::after {
    border-color: transparent;
    border-width: ${ARROW_SIZE}px;
    ${props =>
      `border-${props.direction}-color: ${bgColorSchemeVariants(props)};`};
  }
`;

TooltipContent.defaultProps = {
  offset: 0,
};

export default TooltipContent;
