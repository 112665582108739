import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import * as CustomPropTypes from 'src/utils/custom-prop-types';

export default class Scrollbar extends Component {
  componentDidMount() {
    const { distanceFromContent, maxWidth } = this.props;
    const width = Number.isInteger(maxWidth) ? `${maxWidth}px` : maxWidth;

    this.element.container.style.width = `calc(${width} + ${distanceFromContent}px)`;
    this.element.view.style.paddingRight = `${distanceFromContent}px`;
  }

  render() {
    const { id, className, children, height, maxHeight } = this.props;
    let props;

    if (height) {
      props = {
        style: { height },
      };
    } else {
      props = {
        autoHeight: true,
        autoHeightMax: maxHeight,
      };
    }

    props = Object.assign(props, { id, className });

    return (
      <Scrollbars
        ref={el => {
          this.element = el;
        }}
        {...props}
      >
        {children}
      </Scrollbars>
    );
  }
}

Scrollbar.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: CustomPropTypes.oneOrMoreOfType([PropTypes.element]).isRequired,
  distanceFromContent: PropTypes.number,
  height: PropTypes.string,
  maxHeight: CustomPropTypes.numberOrString,
  maxWidth: CustomPropTypes.numberOrString,
};

Scrollbar.defaultProps = {
  id: null,
  className: null,
  distanceFromContent: 0,
  height: null,
  maxHeight: '100%',
  maxWidth: '100%',
};

Scrollbar.displayName = 'Scrollbar';
