export const isLeapYear = year =>
  year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

export const createDays = ({ month, year } = {}) => {
  let totalDays = 30;

  if (['0', '2', '4', '6', '7', '9', '11'].includes(month)) totalDays = 31;

  if (month === '1') {
    if (year && isLeapYear(Number(year))) totalDays = 29;
    else totalDays = 28;
  }

  return new Array(totalDays)
    .fill()
    .map((_, index) => (day => ({ label: day, value: day }))(`${index + 1}`));
};

export const createYears = ({ minYear, maxYear } = {}) => {
  const earliestBirthYear = 1903;
  const earliestYear = minYear || earliestBirthYear;
  const latestYear = maxYear ? maxYear + 1 : new Date().getUTCFullYear() + 1;
  const yearsRange = latestYear - earliestYear;

  return new Array(yearsRange)
    .fill()
    .map((_, index) =>
      (year => ({ label: year, value: year }))(`${earliestYear + index}`),
    )
    .reverse();
};

export const validateDate = args => {
  const map = {
    DD: 'day',
    MM: 'month',
    YYYY: 'year',
  };

  return args.format.split('-').every(key => args[map[key]]);
};

export const dates = {
  day: {
    label: 'Day',
    name: 'day',
  },
  month: {
    label: 'Month',
    name: 'month',
    options: [
      {
        label: 'January',
        value: '0',
      },
      {
        label: 'February',
        value: '1',
      },
      {
        label: 'March',
        value: '2',
      },
      {
        label: 'April',
        value: '3',
      },
      {
        label: 'May',
        value: '4',
      },
      {
        label: 'June',
        value: '5',
      },
      {
        label: 'July',
        value: '6',
      },
      {
        label: 'August',
        value: '7',
      },
      {
        label: 'September',
        value: '8',
      },
      {
        label: 'October',
        value: '9',
      },
      {
        label: 'November',
        value: '10',
      },
      {
        label: 'December',
        value: '11',
      },
    ],
  },
  year: {
    label: 'Year',
    name: 'year',
  },
};
