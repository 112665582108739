import styled from 'styled-components';

const ProofFieldContainer = styled.div`
  display: inline-flex;

  > div:last-child {
    margin-left: ${props => props.theme.space[3]}px;
  }
`;

export default ProofFieldContainer;
