import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingIndicator = styled.img`
  animation: ${spin} 0.9s linear infinite;
  height: 43px;
  margin: 60px auto;
  width: 43px;
`;

export default LoadingIndicator;
