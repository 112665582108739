import React from 'react';
import PropTypes from 'prop-types';

import HorizontalRule from './styles/HorizontalRule';
import { SIZE_OPTIONS } from './constants';

const Separator = ({ children, ...props }) => (
  <HorizontalRule {...props}>{children}</HorizontalRule>
);

Separator.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(SIZE_OPTIONS),
};

Separator.defaultProps = {
  id: null,
  className: null,
  children: null,
  size: 'small',
};

export default Separator;
