import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Flex } from '@rebass/grid';

import HintMessage from '../HintMessage';
import CharCounter from '../CharCounter';

import { TYPE_OPTIONS } from './constants';

import TextAreaInput from './styles/TextAreaInput';
import TextFieldContainer from './styles/TextFieldContainer';
import TextFieldInput from './styles/TextFieldInput';
import TextFieldLabel from './styles/TextFieldLabel';
import TextFieldRequiredIcon from './styles/TextFieldRequiredIcon';

class TextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
    };
  }

  componentWillReceiveProps({ value }) {
    if (this.state.value !== value) {
      this.setState({ value: value || '' });
    }
  }

  handleChange = event => {
    const { onChange } = this.props;
    const { value } = event.target;

    this.setState({ value });

    if (onChange) {
      onChange(event);
    }
  };

  render() {
    const {
      id,
      className,
      autoFocus,
      disabled,
      error,
      heading,
      hint,
      label,
      maxLength,
      name,
      onBlur,
      placeholder,
      required,
      type,
    } = this.props;
    const { value } = this.state;
    const TextInput = type === 'textarea' ? TextAreaInput : TextFieldInput;

    return (
      <TextFieldContainer id={id} className={className}>
        {required && !value && (
          <TextFieldRequiredIcon size={heading ? 'medium' : 'small'} />
        )}
        <Flex flexDirection="column-reverse">
          <TextInput
            id={`${name}-input-${type}`}
            className={classNames({ empty: !value, error })}
            autoFocus={autoFocus}
            disabled={disabled}
            heading={heading}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={this.handleChange}
            placeholder={`${placeholder || label}${required ? '*' : ''}`}
            required={required}
            type={type}
            value={value}
          />
          {label && (
            <TextFieldLabel
              htmlFor={`${name}-input-${type}`}
              floated={label && placeholder}
            >
              {label || placeholder}
              {required && '*'}
            </TextFieldLabel>
          )}
        </Flex>
        {maxLength && (
          <CharCounter text={value} maxLength={maxLength} float="right" />
        )}
        {error && (
          <HintMessage size="tiny" kind="error">
            {error}
          </HintMessage>
        )}
        {hint && <HintMessage size="tiny">{hint}</HintMessage>}
      </TextFieldContainer>
    );
  }
}

TextField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.node,
  heading: PropTypes.bool,
  hint: PropTypes.node,
  label: PropTypes.node,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  /**
   * TYPE_OPTIONS:
   * email
   * password
   * text
   * textarea
   * url
   */
  type: PropTypes.oneOf(TYPE_OPTIONS),
  value: PropTypes.node,
};

TextField.defaultProps = {
  id: null,
  className: null,
  autoFocus: false,
  disabled: false,
  error: null,
  heading: null,
  hint: null,
  label: null,
  maxLength: null,
  onBlur: null,
  onChange: null,
  placeholder: null,
  required: false,
  type: 'text',
  value: '',
};

export default TextField;
