const baselineGrid = 4;
const interfaceGrid = 8;

// @media screen and (min-width: 36em) - 576px
// @media screen and (min-width: 48em) - 768px
// @media screen and (min-width: 62em) - 992px
// @media screen and (min-width: 75em) - 1.200px
// @media screen and (min-width: 88em) - 1.408px
const breakpoints = ['36em', '48em', '62em', '75em', '88em'];

const colors = {
  primary: {
    default: '#6800c3',
    fallback: '#a55cff',
    text: '#fff',
  },

  gray: {
    default: '#7e7e7e',
    light: '#bfbfbf',
    ultraLight: '#f7f7f7',

    // scale
    '050': '#fafafa',
    100: '#f5f5f5',
    200: '#eee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },

  cold: {
    gray: {
      200: '#e9eef0',
    },
  },

  error: {
    default: '#da1616',
    dark: '#ac1111',
    light: '#f8b9b9',
  },

  success: {
    default: '#28a354',
    dark: '#1e7b3f',
    light: '#c1f0d2',
  },

  warning: {
    default: '#ff9f00',
    dark: '#f28100',
    light: '#ffg899',
    text: '#db8800',
  },

  white: '#fff',
  black: '#000',
  text: '#000',
};

const fontFamilies = {
  icons: 'Material Icons',
  sansSerif: 'CalibreWeb, sans-serif',
  serif: 'ITC-Charter, serif',
};

const fontSizes = [
  10, // 0
  12, // 1
  14, // 2
  16, // 3
  18, // 4
  20, // 5
  24, // 6
  28, // 7
  36, // 8
  52, // 9
  68, // 10
  84, // 11
  116, // 12
  148, // 13
];
const fontSizeNames = {
  action: {
    normal: fontSizes[2],
    large: fontSizes[4],
  },
  body: {
    normal: fontSizes[3],
    serif: fontSizes[3] - 1,
  },
  caption: {
    normal: fontSizes[2],
    small: fontSizes[1],
  },
  display: fontSizes[11],
  h1: fontSizes[8],
  h2: fontSizes[6],
  h3: fontSizes[2],
  h4: fontSizes[3],
  print: {
    body: {
      normal: fontSizes[0],
      serif: fontSizes[0] - 1,
    },
    h1: fontSizes[6],
    h2: fontSizes[3] - 1,
    h3: fontSizes[1],
    h4: fontSizes[0],
  },
};

const fontWeights = {
  sansSerif: {
    normal: 300,
    bold: 500,
  },
  serif: {
    normal: 'normal',
    bold: 'bold',
  },
};

const letterSpacings = [
  '0.3px', // 0
  '0.4px', // 1
  '0.5px', // 2
  '0.6px', // 3
  '0.8px', // 4
  '1px', // 5
  '1.2px', // 6
  '1.3px', // 7
];
const lineHeights = [16, 20, 24, 28, 32, 40];

const shadows = {
  tiny: '0 1px 2px 0 rgba(0, 0, 0, 0.08);', // list item
  soft: '0 0 16px 0 rgba(0, 0, 0, 0.08);', // sidebar
  small: '0 2px 4px 0 rgba(0, 0, 0, 0.15);', // top bar
  medium: '0 4px 10px 0 rgba(0, 0, 0, 0.15);', // popover
  large: '0 6px 16px 2px rgba(0, 0, 0, 0.08);', // modal
};

// [ 0, 4, 8, 16, 24, 32, 48, 64, 128, 256, 512 ]
const space = [
  interfaceGrid * 0,
  interfaceGrid * 0.5,
  interfaceGrid * 1,
  interfaceGrid * 2,
  interfaceGrid * 3,
  interfaceGrid * 4,
  interfaceGrid * 6,
  interfaceGrid * 8,
  interfaceGrid * 16,
  interfaceGrid * 32,
  interfaceGrid * 64,
];

const navigationHeight = 50;

const zIndexes = {
  modal: {
    panel: 250,
    overlay: 249,
  },
  dropdown: {
    panel: 300,
    overlay: 299,
  },
};

/*
  Theme structure based on our grid-system
  and its underlying library styled-system
 */
export default {
  baselineGrid,
  breakpoints,

  colors,

  fontFamilies,
  fontSizes,
  fontSizeNames,
  fontWeights,

  letterSpacings,
  lineHeights,

  navigationHeight,

  shadows,
  space,

  zIndexes,
};
