import styled from 'styled-components';
import { colorProperty } from 'src/mixins/common-properties/color';

import { numberSizeVariants, sizeVariants } from './variants';

const activeBorder = props => sizeVariants(props) + 10;

const leftPos = props => (props.content === 1 ? '34.5%' : '29.5%');

const IconNumber = styled.i`
  border: 2px solid currentColor;
  border-radius: 50%;
  color: ${colorProperty};
  direction: ltr;
  display: inline-block;
  height: ${sizeVariants}px;
  font-family: ${props => props.theme.fontFamilies.sansSerif};
  font-size: 0;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-align: center;
  text-transform: none;
  vertical-align: middle;
  white-space: nowrap;
  width: ${sizeVariants}px;
  word-wrap: normal;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;

  &::after {
    color: ${colorProperty};
    content: '${props => props.children}';
    font-size: ${numberSizeVariants}px;
    position: absolute;
    top: 0;
    left: ${leftPos};
  }

  &.active,
  .active & {
    background-color: currentColor;

    &::after {
      color: ${props => props.theme.colors.white};
    }

    &::before {
      content: '';
      border: 2px solid currentColor;
      border-radius: 50%;
      height: ${activeBorder}px;
      position: absolute;
      width: ${activeBorder}px;
      top: -7px;
      left: -7px;
    }
  }
`;

IconNumber.defaultProps = {
  color: 'primary',
  size: 'medium',
};

export default IconNumber;
