import { Children, cloneElement, Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';

import DropdownContext from './DropdownContext';
import DropdownItem from './DropdownItem';

const DropdownGroup = props => {
  const context = useContext(DropdownContext);
  const { disabled: groupDisabled } = props;
  let { children } = props;

  if (isFunction(children)) {
    children = children(context);

    if (children.type === Fragment) {
      children = children.props.children; // eslint-disable-line
    }
  }

  const allChildrenDisabled = children.every(child => {
    if (child && child.type === DropdownItem) {
      return child.props.disabled;
    }
    return true;
  });

  children = Children.map(children, child =>
    cloneElement(child, {
      disabled: child.props.disabled || groupDisabled || allChildrenDisabled,
    }),
  );

  return children;
};

DropdownGroup.propTypes = {
  disabled: PropTypes.bool,
  closeMenu: PropTypes.func,
  setDeleteModalFor: PropTypes.func,
  multiGroup: PropTypes.bool,
};

DropdownGroup.defaultProps = {
  disabled: false,
};

export default DropdownGroup;
