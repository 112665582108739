import styled from 'styled-components';

import HelpcenterIcon from 'src/components/HelpcenterIcon';

const NotFoundPageHelpcenterIcon = styled(HelpcenterIcon)`
  bottom: 0;
  left: 0;
  position: absolute;
`;

export default NotFoundPageHelpcenterIcon;
