import { textStyle } from './utils';

export const captionTextStyle = (props, customValues) =>
  textStyle({
    fontFamily: props.theme.fontFamilies.sansSerif,
    fontSize: `${props.theme.fontSizeNames.caption.normal}px`,
    fontWeight: props.bold
      ? props.theme.fontWeights.sansSerif.bold
      : props.theme.fontWeights.sansSerif.normal,
    letterSpacing: props.theme.letterSpacings[5],
    lineHeight: `${props.theme.lineHeights[1]}px`,
    ...customValues,
  });

export const captionSmallTextStyle = (props, customValues) =>
  captionTextStyle(props, {
    fontSize: `${props.theme.fontSizeNames.caption.small}px`,
    letterSpacing: props.theme.letterSpacings[6],
    lineHeight: `${props.theme.lineHeights[0]}px`,
    ...customValues,
  });
