import styled from 'styled-components';
import { transparentize } from 'polished';

import transition from 'src/mixins/css-transitions';
import { colorProperty } from 'src/mixins/common-properties/color';

import IconGlyph from './IconGlyph';

const interactiveBackgroundStyles = props => {
  const { interactiveColor } = props;
  const color = /^(transparent|inherit)$/.test(interactiveColor)
    ? colorProperty({ ...props, interactiveColor: 'black' }, 'interactiveColor')
    : colorProperty(props, 'interactiveColor');

  return `
    &:hover,
    &.active {
      background-color: ${transparentize(0.88, color)};
    }

    &:active {
      background-color: ${transparentize(0.74, color)};
    }
  `;
};

const InteractiveIconGlyph = styled(IconGlyph)`
  ${interactiveBackgroundStyles};
  cursor: pointer;
  transition: ${transition({ property: 'background-color' })};
`;

export default InteractiveIconGlyph;
