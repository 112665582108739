import { createElement } from 'react';
import PropTypes from 'prop-types';

import { colorPropType } from 'src/mixins/common-properties';
import { SIZE_OPTIONS } from '../constants';

import Merged from '../styles/IconGroupMerged';
import Stacked from '../styles/IconGroupStacked';

const Group = {
  merged: Merged,
  stacked: Stacked,
};

const IconGroup = ({ id, className, children, mode, outline, size }) =>
  createElement(Group[mode], {
    id,
    className,
    children,
    outline,
    size,
  });

IconGroup.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  mode: PropTypes.oneOf(['merged', 'stacked']),
  /**
   * Only for mode="stacked"
   */
  outline: colorPropType,
  /**
   * Only for mode="merged"
   * SIZE_OPTIONS:
   * tiny
   * small
   * medium
   * large
   * huge
   */
  size: PropTypes.oneOf(SIZE_OPTIONS),
};

IconGroup.defaultProps = {
  id: null,
  className: null,
  mode: 'stacked',
  outline: 'transparent',
  size: 'medium',
};

IconGroup.displayName = 'IconGroup';

export default IconGroup;
