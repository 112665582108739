import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { COLOR_SCHEME_OPTIONS } from './constants';
import TooltipWrapper from './styles/TooltipWrapper';
import TooltipContent from './styles/TooltipContent';

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    const { direction } = this.props;

    this.direction = direction || this.getDirection();
    this.forceUpdate();
  }

  componentWillUpdate(nextProps, nextState) {
    const { open } = this.state;
    const { direction } = this.props;

    if (open !== nextState.open) {
      this.direction = direction || this.getDirection();
    }
  }

  getDirection() {
    const { axis } = this.props;
    const { top, right, bottom, left } = this.element.getBoundingClientRect();
    const bottomDistance = window.innerHeight - bottom;
    const rightDistance = window.innerWidth - right;
    const isHorizontalAxis = axis === 'horizontal';

    const posY = top > bottomDistance ? 'top' : 'bottom';
    const posX = left > rightDistance ? 'left' : 'right';

    return isHorizontalAxis ? posX : posY;
  }

  open = () => this.setState({ open: true });

  close = () => this.setState({ open: false });

  render() {
    const {
      id,
      className,
      axis,
      colorScheme,
      message,
      children,
      offset,
      open,
      shift,
      textAlign,
      width,
    } = this.props;

    return (
      <TooltipWrapper
        id={id}
        className={className}
        ref={ref => {
          this.element = ref;
        }}
        onMouseOver={this.open}
        onFocus={this.open}
        onMouseOut={this.close}
        onBlur={this.close}
      >
        {children}
        {(open || this.state.open) && (
          <TooltipContent
            axis={axis}
            colorScheme={colorScheme}
            direction={this.direction}
            width={width}
            offset={offset}
            role="tooltip"
            shift={shift}
            textAlign={textAlign}
          >
            {message}
          </TooltipContent>
        )}
      </TooltipWrapper>
    );
  }
}

Tooltip.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  axis: PropTypes.oneOf(['horizontal', 'vertical']),
  /**
   * COLOR_SCHEME_OPTIONS:
   * black
   * error
   * white
   */
  colorScheme: PropTypes.oneOf(COLOR_SCHEME_OPTIONS),
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  offset: PropTypes.number,
  open: PropTypes.bool,
  shift: PropTypes.oneOf(['bottom', 'center', 'left', 'right', 'top']),
  textAlign: PropTypes.string,
  width: PropTypes.string,
};

Tooltip.defaultProps = {
  id: null,
  axis: 'vertical',
  className: null,
  colorScheme: 'black',
  direction: null,
  offset: 0,
  open: false,
  shift: 'center',
  textAlign: 'left',
  width: null,
};

export default Tooltip;
