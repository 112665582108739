import React from 'react';
import PropTypes from 'prop-types';

import HintMessage from 'src/components/HintMessage';

import Container from '../styles/RadioFieldContainer';
import HiddenInput from '../styles/RadioFieldHiddenInput';
import Label from '../styles/RadioFieldLabel';

const RadioField = ({
  id,
  className,
  checked,
  disabled,
  error,
  hint,
  label,
  name,
  onBlur,
  onChange,
  value,
}) => (
  <Container id={id} className={className}>
    <HiddenInput
      id={`${value}-input-radio`}
      name={name || value}
      checked={checked}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
    />
    <Label htmlFor={`${value}-input-radio`}>{label}</Label>
    {hint && <HintMessage>{hint}</HintMessage>}
    {error && (
      <HintMessage size="small" kind="error">
        {error}
      </HintMessage>
    )}
  </Container>
);

RadioField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.node,
  hint: PropTypes.string,
  label: PropTypes.node.isRequired,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
};

RadioField.defaultProps = {
  id: null,
  className: null,
  checked: null,
  disabled: null,
  name: null,
  error: null,
  hint: null,
  onBlur: null,
  onChange: null,
};

RadioField.displayName = 'RadioField';

export default RadioField;
