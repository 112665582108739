import React from 'react';
import PropTypes from 'prop-types';
import { colorPropType, textStylePropType } from 'src/mixins/common-properties';
import Anchor from './styles/Anchor';

const A = ({ children, ...props }) => <Anchor {...props}>{children}</Anchor>;

A.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  bold: PropTypes.bool,
  color: colorPropType,
  href: PropTypes.string,
  italic: PropTypes.bool,
  onClick: PropTypes.func,
  textStyle: textStylePropType,
};

A.defaultProps = {
  id: null,
  className: null,
  children: null,
  bold: false,
  color: 'primary',
  href: null,
  italic: false,
  onClick: null,
  textStyle: 'body',
};

export default A;
