import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Media from 'react-media';
import isString from 'lodash.isstring';

const MediaQuery = withTheme(({ query, theme, ...props }) => (
  <Media
    query={
      isString(query)
        ? query
        : Object.entries(query).reduce((queries, [rule, breakpoint]) => {
            queries[rule] = theme.breakpoints[breakpoint]; // eslint-disable-line
            return queries;
          }, {})
    }
    {...props}
  />
));

MediaQuery.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  defaultMatches: PropTypes.bool,
  onChange: PropTypes.func,
  query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  render: PropTypes.func,
  targetWindow: PropTypes.object, // eslint-disable-line
};

MediaQuery.displayName = 'MediaQuery';

export default MediaQuery;
