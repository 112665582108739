import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'src/components/Tooltip';

import ApproveFlagSVG from './ApproveFlagSVG';
import HiddenRadio from '../styles/HiddenRadio';
import Container from '../styles/ProofFieldContainer';
import Label from '../styles/ProofFieldLabel';
import RejectFlagSVG from './RejectFlagSVG';

class ProofField extends Component {
  handleChange = event => {
    const { value: originalValue } = event.target;
    const { onChange } = this.props;
    const parsedValue = JSON.parse(originalValue);

    if (onChange) {
      onChange(event, parsedValue);
    }
  };

  handleClick = event => {
    const { value: originalValue } = event.target;
    const { onChange, value } = this.props;
    const parsedValue = JSON.parse(originalValue);

    if (parsedValue === value && onChange) {
      event.target.value = null; // eslint-disable-line
      onChange(event, null);
    }
  };

  render() {
    const { id, className, name, value } = this.props;
    return (
      <Container id={id} className={className}>
        <HiddenRadio
          id={`null-${name}`}
          name={name}
          value="null"
          checked={value === null}
          onChange={this.handleChange}
        />
        <Tooltip colorScheme="whitenblack" direction="bottom" message="Approve">
          <HiddenRadio
            id={`true-${name}`}
            name={name}
            type="radio"
            value="true"
            checked={value === true}
            onChange={this.handleChange}
            onClick={this.handleClick}
          />
          <Label htmlFor={`true-${name}`}>
            <ApproveFlagSVG />
          </Label>
        </Tooltip>
        <Tooltip colorScheme="whitenblack" direction="bottom" message="Reject">
          <HiddenRadio
            id={`false-${name}`}
            name={name}
            type="radio"
            value="false"
            checked={value === false}
            onChange={this.handleChange}
            onClick={this.handleClick}
          />
          <Label htmlFor={`false-${name}`}>
            <RejectFlagSVG />
          </Label>
        </Tooltip>
      </Container>
    );
  }
}

ProofField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

ProofField.defaultProps = {
  id: null,
  className: null,
  onChange: null,
  value: null,
};

ProofField.displayName = 'ProofField';

export default ProofField;
