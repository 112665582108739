import React from 'react';
import PropTypes from 'prop-types';

import H1 from '../H1';
import H2 from '../H2';
import P from '../P';

import HeaderStyled from './index.styled';

const Header = ({ id, className, headline, subtitle, kind }) => {
  const Headline = { page: H1, section: H2 }[kind];

  return (
    <HeaderStyled id={id} className={className} kind={kind}>
      <Headline noMargin>{headline}</Headline>
      {subtitle && <P color="muted">{subtitle}</P>}
    </HeaderStyled>
  );
};

Header.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  kind: PropTypes.oneOf(['page', 'section']),
  subtitle: PropTypes.node,
  headline: PropTypes.node.isRequired,
};

Header.defaultProps = {
  id: null,
  className: null,
  kind: 'page',
  subtitle: null,
};

export { HeaderStyled };
export default Header;
