import styled from 'styled-components';
import tag from 'clean-tag';
import { color, space } from 'styled-system';
import extendTagBlacklist from 'src/utils/extend-tag-blacklist';

const height = {
  mobile: 50,
  desktop: 80,
};

const GeneralLayoutBottomSection = styled(tag).attrs(({ nav }) => ({
  blacklist: extendTagBlacklist([
    'bottomOffset',
    'fixed',
    'leftOffset',
    'nav',
    'rightOffset',
    'topOffset',
  ]),
  is: nav ? 'nav' : 'footer',
}))`
  ${color};
  ${space};
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.gray['300']};
  display: flex;
  z-index: 150;
  ${props =>
    props.fixed
      ? `
      left: ${props.leftOffset}px;
      position: fixed;
      right: ${props.rightOffset}px;
      top: ${props.bottomOffset - height.mobile}px;

      @media screen and (min-width: ${props.theme.breakpoints[0]}) {
        top: ${props.bottomOffset - height.desktop}px;
      }
    `
      : `
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
  `};
`;

export const BottomSectionSpace = styled.div`
  padding-top: ${height.mobile}px;
  position: relative;
  width: 100%;

  ${GeneralLayoutBottomSection /* sc-sel */} {
    height: ${height.mobile}px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    padding-top: ${height.desktop}px;

    ${GeneralLayoutBottomSection /* sc-sel */} {
      height: ${height.desktop}px;
    }
  }
`;

GeneralLayoutBottomSection.propTypes = {
  ...color.propTypes,
  ...space.propTypes,
};

GeneralLayoutBottomSection.defaultProps = {
  bg: 'gray.100',
  nav: false,
};

export default GeneralLayoutBottomSection;
