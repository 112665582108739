import React from 'react';
import PropTypes from 'prop-types';

import Button from 'src/components/Button';
import H2 from 'src/components/H2';
import P from 'src/components/P';
import FullPageContainer from 'src/styles/FullPageContainer';

import FourOhFourTreySVG from './assets/FourOhFourTreySVG';

const NotFoundPage = ({ onClickAction }) => (
  <FullPageContainer>
    <FourOhFourTreySVG />
    <br />
    <br />
    <H2 color="muted" noMargin>
      This isn&#39;t the thing you&#39;re looking for.
    </H2>
    <P color="muted">Please use the navigation.</P>
    <br />
    <br />
    <Button
      onClick={event =>
        onClickAction ? onClickAction(event) : window.location.replace('/')
      }
      outlined
    >
      Go home!
    </Button>
  </FullPageContainer>
);

NotFoundPage.propTypes = {
  onClickAction: PropTypes.func,
};

NotFoundPage.defaultProps = {
  onClickAction: null,
};

export default NotFoundPage;
