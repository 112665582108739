import React from 'react';
import PropTypes from 'prop-types';
import { colorPropType } from 'src/mixins/common-properties';
import HeadingFour from './styles/HeadingFour';

const H4 = ({ children, ...props }) => (
  <HeadingFour {...props}>{children}</HeadingFour>
);

H4.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  color: colorPropType,
  noMargin: PropTypes.bool,
};

H4.defaultProps = {
  id: null,
  className: null,
  children: null,
  color: 'black',
  noMargin: false,
};

export default H4;
