import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { textStylePropType } from 'src/mixins/common-properties';

import Container from '../styles/ContextualTextFieldContainer';
import HiddenSpan from '../styles/ContextualTextFieldHiddenSpan';
import Input from '../styles/ContextualTextFieldInput';

class ContextualTextField extends Component {
  constructor(props) {
    super(props);

    this.inputRef = createRef();
    this.spanRef = createRef();
  }

  componentDidMount() {
    const { current: inputElem } = this.inputRef;
    const { current: spanElem } = this.spanRef;
    const rect = spanElem.getBoundingClientRect();

    inputElem.style.width = `${rect.width}px`;
  }

  componentDidUpdate() {
    const { current: inputElem } = this.inputRef;
    const { current: spanElem } = this.spanRef;
    const rect = spanElem.getBoundingClientRect();

    inputElem.style.width = `${rect.width}px`;
  }

  handleKeyPress = event => {
    const { current: inputElem } = this.inputRef;
    const { key } = event;

    if (inputElem && key === 'Enter') {
      inputElem.blur();
    }
  };

  handleKeyUp = event => {
    const { current: inputElem } = this.inputRef;
    const { key } = event;

    if (inputElem && key === 'Escape') {
      inputElem.blur();
    }
  };

  focus() {
    const { current: inputElem } = this.inputRef;
    if (inputElem) {
      inputElem.focus();
    }
  }

  render() {
    const {
      id,
      className,
      autoFocus,
      disabled,
      maxWidth,
      minWidth,
      onBlur,
      onChange,
      placeholder,
      textAlign,
      textStyle,
      value,
      ...props
    } = this.props;

    return (
      <Container
        id={id}
        className={className}
        maxWidth={maxWidth}
        minWidth={minWidth}
        textAlign={textAlign}
        textStyle={textStyle}
      >
        <HiddenSpan ref={this.spanRef}>{value || placeholder}</HiddenSpan>
        <Input
          {...props}
          autoFocus={autoFocus}
          disabled={disabled}
          ref={this.inputRef}
          onBlur={onBlur}
          onChange={onChange}
          onInput={this.handleInput}
          onKeyPress={this.handleKeyPress}
          onKeyUp={this.handleKeyUp}
          placeholder={placeholder}
          value={value}
        />
      </Container>
    );
  }
}

ContextualTextField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  textAlign: PropTypes.string,
  textStyle: textStylePropType,
  value: PropTypes.string,
};

ContextualTextField.defaultProps = {
  id: null,
  className: null,
  autoFocus: false,
  disabled: false,
  maxWidth: '100%',
  minWidth: null,
  onBlur: null,
  onChange: null,
  placeholder: null,
  textAlign: 'left',
  textStyle: 'body',
  value: '',
};

export default ContextualTextField;
