import styled from 'styled-components';
import { width, height } from 'styled-system';

const hideIframe = `
  iframe {
    height: 1px !important;
  }
`;

const BannerContainer = styled.div`
  overflow: hidden;
  ${width}
  ${height}
  ${props => !props.isLoaded && hideIframe}
`;

export default BannerContainer;
