import styled from 'styled-components';
import { textStyle } from 'src/mixins/text-styles';

const badgeColor = props => props.color || props.theme.colors.gray['600'];

const Badge = styled.span`
  background-color: ${props => props.theme.colors.white};
  border-radius: 2px;
  border: solid 1px ${badgeColor};
  color: ${badgeColor};
  cursor: default;
  padding: 0.5px 2px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  ${({ theme }) =>
    textStyle({
      fontFamily: theme.fontFamilies.sansSerif,
      fontSize: `${theme.fontSizes[0]}px`,
      lineHeight: `${theme.lineHeights[0]}px`,
      letterSpacing: theme.letterSpacings[3],
      textTransform: 'uppercase',
    })};
`;

export default Badge;
