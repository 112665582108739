import React from 'react';
import PropTypes from 'prop-types';

import Button from 'src/components/Button';
import FullPageContainer from 'src/styles/FullPageContainer';
import FullScreenContainer from 'src/styles/FullScreenContainer';
import GeneralLayout from 'src/layouts/GeneralLayout';
import H2 from 'src/components/H2';
import P from 'src/components/P';
import PicterLogoSVG from 'src/assets/PicterLogoSVG';

import FourOhFourTreySVG from './assets/FourOhFourTreySVG';
import HelpcenterIcon from './styles/NotFoundPageHelpcenterIcon';

const NotFoundPage = ({ onClickAction, onClickHelp }) => (
  <FullScreenContainer>
    <GeneralLayout>
      <GeneralLayout.Top>
        <GeneralLayout.Top.AlignLeft>
          <PicterLogoSVG />
          <Button
            css="text-transform: unset;"
            onClick={event =>
              onClickAction
                ? onClickAction(event)
                : window.location.replace('/')
            }
            flat
          >
            Back to home
          </Button>
        </GeneralLayout.Top.AlignLeft>
      </GeneralLayout.Top>
      <GeneralLayout.Content>
        <FullPageContainer>
          <FourOhFourTreySVG />
          <br />
          <br />
          <H2 color="black" noMargin>
            Not found
          </H2>
          <br />
          <P color="#747d80">Check for typos or find your way through home.</P>
          <HelpcenterIcon onClick={onClickHelp} />
        </FullPageContainer>
      </GeneralLayout.Content>
    </GeneralLayout>
  </FullScreenContainer>
);

NotFoundPage.propTypes = {
  onClickAction: PropTypes.func,
  onClickHelp: PropTypes.func,
};

NotFoundPage.defaultProps = {
  onClickAction: null,
  onClickHelp: null,
};

export default NotFoundPage;
