import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

import * as CustomPropTypes from 'src/utils/custom-prop-types';

import RadioField from './RadioField';

const RadioFieldGroup = ({
  id,
  className,
  children,
  disabled,
  name,
  onBlur,
  onChange,
}) => (
  <div id={id} className={className}>
    {Children.map(children, child =>
      cloneElement(child, {
        disabled,
        name,
        onBlur,
        onChange,
      }),
    )}
  </div>
);

RadioFieldGroup.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: CustomPropTypes.elementsOf(RadioField).isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

RadioFieldGroup.defaultProps = {
  id: null,
  className: null,
  disabled: null,
  onBlur: null,
  onChange: null,
};

export default RadioFieldGroup;
