import styled from 'styled-components';

import { variants } from 'src/utils/theme-helper';
import Icon from 'src/components/Icon';

const TextFieldRequiredIcon = styled(Icon).attrs({
  color: 'warning',
  type: 'forward',
})`
  position: absolute;
  left: 0;
  bottom: ${variants('size', {
    small: 7,
    medium: 9,
  })}px;
`;

export default TextFieldRequiredIcon;
