import styled from 'styled-components';

import { sizeVariants } from './variants';

const HorizontalRule = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.gray['300']};
  ${sizeVariants};
`;

HorizontalRule.defaultProps = {
  children: null,
  size: 'small',
};

export default HorizontalRule;
