import React from 'react';
import PropTypes from 'prop-types';
import H4 from 'src/components/H4';
import Icon from 'src/components/Icon';

import PopoverHeaderStyled from './PopoverHeaderStyled';

const PopoverHeader = ({ allowClose, children, closePopover }) => (
  <PopoverHeaderStyled>
    <H4 noMargin>{children}</H4>
    {allowClose && (
      <Icon color="muted" interactive onClick={closePopover} type="close" />
    )}
  </PopoverHeaderStyled>
);

PopoverHeader.propTypes = {
  allowClose: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  closePopover: PropTypes.func.isRequired,
};

PopoverHeader.defaultProps = {
  allowClose: false,
};

export default PopoverHeader;
