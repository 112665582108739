import styled from 'styled-components';
import { alignItems, flexDirection, justifyContent } from 'styled-system';

const FullPageContainer = styled.div`
  ${alignItems};
  ${flexDirection};
  ${justifyContent};
  background-color: ${props => props.theme.grey100Color};
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
`;

FullPageContainer.propTypes = {
  ...alignItems.propTypes,
  ...flexDirection.propTypes,
  ...justifyContent.propTypes,
};

FullPageContainer.defaultProps = {
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
};

export default FullPageContainer;
