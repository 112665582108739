import styled from 'styled-components';

import HintMessage from 'src/components/HintMessage/styles/HintText';

const SelectFieldContainer = styled.div`
  display: inline-block;
  margin: 0 0 ${props => props.theme.space[3]}px;
  position: relative;
  text-align: left;
  width: ${props => (props.width ? props.width : '100%')};

  ${HintMessage /* sc-sel */} {
    margin-top: ${props => props.theme.space[1]}px;
    margin-left: 4px;
  }
`;

export default SelectFieldContainer;
