import styled from 'styled-components';
import { width, height } from 'styled-system';

const BannerFrame = styled.iframe`
  ${width}
  ${height}
  border: 0;
`;

export default BannerFrame;
