import { SIZES_MAP as ICON_SIZES_MAP } from 'src/components/Icon/constants';

export const HEIGHT_SIZES_MAP = ICON_SIZES_MAP;
export const WIDTH_SIZES_MAP = {
  tiny: ICON_SIZES_MAP.tiny + 4,
  xsmall: ICON_SIZES_MAP.xsmall + 4,
  small: ICON_SIZES_MAP.small + 4,
  medium: ICON_SIZES_MAP.medium + 4,
  large: ICON_SIZES_MAP.large + 4,
  xlarge: ICON_SIZES_MAP.xlarge + 8,
  huge: ICON_SIZES_MAP.huge + 8,
  xhuge: ICON_SIZES_MAP.xhuge + 8,
};
export const SIZE_OPTIONS = Object.keys(WIDTH_SIZES_MAP);
