import styled from 'styled-components';
import PropTypes from 'prop-types';
import tag from 'clean-tag';
import { minHeight, space } from 'styled-system';

import extendTagBlacklist from 'src/utils/extend-tag-blacklist';
import AlignCenter from 'src/styles/FlexAlignCenter';
import AlignLeft from 'src/styles/FlexAlignLeft';
import AlignRight from 'src/styles/FlexAlignRight';

const DetailLayoutTop = styled(tag).attrs(({ nav }) => ({
  blacklist: extendTagBlacklist('nav'),
  is: nav ? 'nav' : 'header',
}))`
  ${minHeight};
  ${space};
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.cold.gray['200']};
  display: flex;
  width: 100%;
  z-index: 150;
`;

DetailLayoutTop.propTypes = {
  ...minHeight.propTypes,
  nav: PropTypes.bool,
  ...space.propTypes,
};

DetailLayoutTop.defaultProps = {
  minHeight: [48, 64],
  nav: false,
  px: [3, 4],
};

DetailLayoutTop.AlignCenter = AlignCenter;
DetailLayoutTop.AlignLeft = AlignLeft;
DetailLayoutTop.AlignRight = AlignRight;

export default DetailLayoutTop;
