import React from 'react';
import PropTypes from 'prop-types';

import Container from '../styles/ToastContainer';

const Toast = ({ id, className, children, colorScheme }) => (
  <Container id={id} className={className} colorScheme={colorScheme}>
    {children}
  </Container>
);

Toast.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  colorScheme: PropTypes.string,
};

Toast.defaultProps = {
  id: null,
  className: null,
  colorScheme: 'black',
};

Toast.displayName = 'Toast';

export default Toast;
