import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

import KeyboardistNoSSR from 'src/components/KeyboardistNoSSR';

import PopoverContentStyled from './PopoverContentStyled';
import PopoverHeader from './PopoverHeader';

class PopoverContent extends React.Component {
  handleClickOutside = e => {
    this.props.closePopover();
    e.stopPropagation();
  };

  render() {
    const { closePopover, width } = this.props;
    const children = Children.map(this.props.children, child => {
      if (child && child.type === PopoverHeader) {
        return cloneElement(child, {
          closePopover,
        });
      }
      return child;
    });

    return (
      <React.Fragment>
        <KeyboardistNoSSR bindings={{ Escape: closePopover }} />
        <PopoverContentStyled width={width}>{children}</PopoverContentStyled>
      </React.Fragment>
    );
  }
}

PopoverContent.propTypes = {
  children: PropTypes.node.isRequired,
  closePopover: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
};

const PopoverContentWithClickOutside = onClickOutside(PopoverContent);
PopoverContentWithClickOutside.displayName = 'PopoverContent';

PopoverContentWithClickOutside.Header = PopoverHeader;

export default PopoverContentWithClickOutside;
