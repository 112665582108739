import React from 'react';
import PropTypes from 'prop-types';
import { colorPropType, textStylePropType } from 'src/mixins/common-properties';

import Paragraph from './styles/Paragraph';

const P = ({ children, ...props }) => (
  <Paragraph {...props}>{children}</Paragraph>
);

P.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  bold: PropTypes.bool,
  pre: PropTypes.bool,
  /**
   * COLOR_OPTIONS:
   * primary
   * secondary
   * success
   * error
   * warning
   * black
   * white
   * muted
   * Hex number
   */
  color: colorPropType,
  noMargin: PropTypes.bool,
  italic: PropTypes.bool,
  /**
   * TEXT_STYLE_OPTIONS:
   * body
   * bodySerif
   * caption
   * captionSmall
   */
  textStyle: textStylePropType,
};

P.defaultProps = {
  id: null,
  className: null,
  bold: false,
  pre: false,
  color: 'black',
  italic: false,
  noMargin: false,
  textStyle: 'body',
};

export default P;
