import React from 'react';
import PropTypes from 'prop-types';

import AlignCenter from 'src/styles/FlexAlignCenter';
import AlignLeft from 'src/styles/FlexAlignLeft';
import AlignRight from 'src/styles/FlexAlignRight';
import BottomSection, {
  BottomSectionSpace,
} from '../styles/GeneralLayoutBottomSection';
import { LayoutInfoConsumer } from '../utils/layout-info';

const GeneralLayoutBottom = props => (
  <LayoutInfoConsumer>
    {layoutInfo => (
      <BottomSectionSpace>
        <BottomSection {...props} {...layoutInfo} />
      </BottomSectionSpace>
    )}
  </LayoutInfoConsumer>
);

GeneralLayoutBottom.propTypes = {
  children: PropTypes.node,
  fixed: PropTypes.bool,
  nav: PropTypes.bool,
};

GeneralLayoutBottom.defaultProps = {
  children: null,
  fixed: false,
  nav: false,
};

GeneralLayoutBottom.AlignCenter = AlignCenter;
GeneralLayoutBottom.AlignLeft = AlignLeft;
GeneralLayoutBottom.AlignRight = AlignRight;

export default GeneralLayoutBottom;
