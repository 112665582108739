import React from 'react';
import PropTypes from 'prop-types';

import CharAmount from './styles/CharAmount';

const CharCounter = ({
  id,
  className,
  float,
  maxLength,
  minLength,
  size,
  text,
}) => {
  const textLength = text ? text.length || 0 : 0;
  const showWarning = textLength > maxLength || minLength > textLength;

  return (
    <CharAmount
      id={id}
      className={className}
      float={float}
      size={size}
      showWarning={showWarning}
    >
      {textLength} / {maxLength}
    </CharAmount>
  );
};

CharCounter.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  float: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  minLength: PropTypes.number,
};

CharCounter.defaultProps = {
  id: null,
  className: null,
  float: null,
  minLength: 0,
  size: 'tiny',
};

export default CharCounter;
