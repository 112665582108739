/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../styles/AvatarIcon';

const ICON_PROPERTIES = {
  user: {
    small: {
      type: 'account_circle',
      glyphSize: 'xlarge',
      size: 'medium',
    },
    default: {
      type: 'account_circle',
      glyphSize: 'xhuge',
      size: 'xlarge',
    },
  },
  coordinator: {
    small: {
      type: 'business',
      glyphSize: 'xsmall',
      size: 'medium',
    },
    default: {
      type: 'business',
      glyphSize: 'small',
      size: 'xlarge',
    },
  },
};

const AvatarIcon = ({ id, className, size, type, ...props }) => (
  <Icon
    {...props}
    id={id}
    className={className}
    {...ICON_PROPERTIES[type][size]}
  />
);

AvatarIcon.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['default', 'small']),
  type: PropTypes.oneOf(['coordinator', 'user']),
};

AvatarIcon.defaultProps = {
  id: null,
  className: null,
  size: 'default',
  type: 'user',
};

AvatarIcon.displayName = 'AvatarIcon';

export default AvatarIcon;
