import React, {
  Children,
  cloneElement,
  createRef,
  Component,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import { CSSTransition } from 'react-transition-group';

import Icon from 'src/components/Icon';
import { oneOrMoreOfType } from 'src/utils/custom-prop-types';

import DropdownContainer from '../styles/DropdownContainer';
import DropdownContext from './DropdownContext';
import DropdownMenu from './DropdownMenu';

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.element = createRef();

    this.state = {
      open: false,
      activeIndex: 0,
      lastActiveIndex: 0,
    };

    this.controls = {
      closeMenu: this.closeMenu,
      goBack: this.goBack,
      goTo: this.goTo,
      posX: 'left',
      posY: 'bottom',
    };
  }

  componentWillUpdate(nextProps, nextState) {
    const { open } = this.state;

    if (!open && nextState.open) {
      const {
        top,
        right,
        bottom,
        left,
      } = this.element.current.getBoundingClientRect();
      const bottomDistance = window.innerHeight - bottom;
      const rightDistance = window.innerWidth - right;

      const posY = top > bottomDistance ? 'top' : 'bottom';
      const posX = left > rightDistance ? 'left' : 'right';

      this.controls.posX = posX;
      this.controls.posY = posY;
    }
  }

  toggleElement = () => {
    const { open } = this.state;
    const { button, icon } = this.props;

    return cloneElement(button || icon, {
      active: open,
      onClick: this.toggleMenu,
    });
  };

  closeMenu = () => this.setState({ activeIndex: 0, open: false });

  toggleMenu = event => {
    event.persist();
    const { onClick } = this.props;
    this.setState(({ open: prevOpen }) => {
      const open = !prevOpen;
      if (onClick) {
        onClick(event, open);
      }
      return { open };
    });
  };

  goBack = () =>
    this.setState(({ lastActiveIndex }) => ({
      activeIndex: lastActiveIndex,
    }));

  goTo = index =>
    this.setState(({ activeIndex }) => ({
      lastActiveIndex: activeIndex,
      activeIndex: index,
    }));

  render() {
    const { activeIndex, open } = this.state;
    const { className, id } = this.props;
    let { children } = this.props;

    if (isFunction(children)) {
      children = children(this.controls);

      // necessary to get the right index
      if (children.type === Fragment) {
        children = children.props.children; // eslint-disable-line
      }
    }

    return (
      <DropdownContainer id={id} className={className} ref={this.element}>
        {this.toggleElement()}
        <DropdownContext.Provider value={this.controls}>
          <CSSTransition
            classNames="dropdown-menu"
            in={open}
            timeout={250}
            unmountOnExit
          >
            {Children.map(children, c => c).find(
              (child, index) => index === activeIndex && child,
            )}
          </CSSTransition>
        </DropdownContext.Provider>
      </DropdownContainer>
    );
  }
}

Dropdown.Content = DropdownMenu.Content;
Dropdown.Context = DropdownContext;
Dropdown.Group = DropdownMenu.Group;
Dropdown.Header = DropdownMenu.Header;
Dropdown.Item = DropdownMenu.Item;
Dropdown.Separator = DropdownMenu.Separator;
Dropdown.Menu = DropdownMenu;

Dropdown.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: oneOrMoreOfType([PropTypes.element, PropTypes.func, PropTypes.node])
    .isRequired,
  button: PropTypes.element,
  icon: PropTypes.element,
  onClick: PropTypes.func,
};

Dropdown.defaultProps = {
  id: null,
  className: null,
  button: null,
  icon: (
    <Icon
      ariaLabel="Dropdown menu toggle"
      color="muted"
      glyphSize="small"
      size="xlarge"
      title="Toggle button"
      type="more_vert"
      interactive
    />
  ),
  onClick: null,
};
Dropdown.displayName = 'Dropdown';

export default Dropdown;
