import styled from 'styled-components';
import { colorProperty, textStyleProperty } from 'src/mixins/common-properties';
import { textStyleMarginVariants } from './variants';

const Paragraph = styled.p`
  ${textStyleProperty};
  color: ${colorProperty};
  margin: ${textStyleMarginVariants};
  ${props => props.pre && 'white-space: pre-wrap;'}
`;

Paragraph.defaultProps = {
  bold: false,
  pre: false,
  color: 'black',
  italic: false,
  noMargin: false,
  textStyle: 'body',
};

export default Paragraph;
