import styled from 'styled-components';
import { bodyTextStyle, headingTwoTextStyle } from 'src/mixins/text-styles';

import TextInput from './TextInput';

const TextFieldInput = styled(TextInput)`
  ${props => (props.heading ? headingTwoTextStyle : bodyTextStyle)};
  margin-bottom: 1px;
`;

export default TextFieldInput;
