import styled from 'styled-components';

import DescriptionMessage from 'src/components/HintMessage/styles/HintText';

const CheckboxContainer = styled.div`
  margin-bottom: 16px;
  text-align: left;

  ${DescriptionMessage /* sc-sel */} {
    padding-left: 35px;
    padding-top: 6px;
  }
`;

export default CheckboxContainer;
