import styled from 'styled-components';

import Icon from 'src/components/Icon';

const ModalCloseButton = styled(Icon).attrs({
  type: 'close',
  color: 'muted',
  glyphSize: 'small',
  size: 'medium',
  interactive: true,
})`
  position: absolute;
  right: 40px;
  top: 40px;
  padding: 0;
  margin-top: 3px;
`;

export default ModalCloseButton;
