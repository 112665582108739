import PropTypes from 'prop-types';

export const isHexColor = prop => /^#(?:[A-Fa-f0-9]{3}){1,2}$/i.test(prop);

export const hexColor = (props, propName, componentName) => {
  const propValue = props[propName]; // eslint-disable-line
  if (propValue && !isHexColor(propValue)) {
    return new Error(
      `Invalid prop '${propName}' supplied to '${componentName}, needs an hex color, but its value is \`${propValue}\``,
    );
  }
  return null;
};

hexColor.isRequired = (props, propName, componentName) => {
  const propValue = props[propName];
  if (!propValue) {
    return new Error(
      `The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${propValue}\``,
    );
  }
  return hexColor(props, propName, componentName);
};

export const numberOrString = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

/**
 * Validates if the property receives an element from a specific
 * component or components.
 *
 * @param {ComponentClass or Array<ComponentClass>} Components
 */
export const elementOf = Components => {
  const oneOf = Array.isArray(Components) ? Components : [Components];
  return PropTypes.shape({
    type: PropTypes.oneOf(oneOf),
  });
};

/**
 * Validates if the property receives an element or an array of elements from a
 * specific component or components.
 *
 * @param {ComponentClass or Array<ComponentClass>} Components
 */
export const elementsOf = Components => {
  const elementShape = elementOf(Components);
  return PropTypes.oneOfType([elementShape, PropTypes.arrayOf(elementShape)]);
};

/**
 * Validates if the property receives a string that matches a RegExp object.
 *
 * @param {RegExp} regex
 */
export const matches = regex => {
  if (!(regex instanceof RegExp)) {
    return new Error('PropType "matches" accepts only RegExp instances');
  }

  // eslint-disable-next-line
  return (props, propName, componentName) => {
    const propValue = props[propName];
    if (!regex.test(propValue)) {
      return new Error(
        `Invalid prop \`${propName}\`: \`${propValue}\`:  supplied to` +
          ` \`${componentName}\`. Validation failed.`,
      );
    }
  };
};

/**
 * Check if the property receives one or more elements of the defined propTypes
 *
 * @param {Array<PropTypes>} propTypes
 */
export const oneOrMoreOfType = propTypes => {
  const elementType = PropTypes.oneOfType(
    Array.isArray(propTypes) ? propTypes : [propTypes],
  );
  return PropTypes.oneOfType([elementType, PropTypes.arrayOf(elementType)]);
};

// necessary for SSR
const isBrowser = typeof window !== 'undefined';
if (!isBrowser) {
  global.Element = function Element() {};
}
export const refShape = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
]);

export const themeShape = PropTypes.shape({
  breakpoints: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.shape({
    modes: PropTypes.object,
  }),
  fonts: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.object,
  ]),
  fontSizes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.object,
  ]),
  fontWeights: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.object,
  ]),
  letterSpacings: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.object,
  ]),
  lineHeights: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.object,
  ]),
  mediaQueries: PropTypes.object,
  shadows: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.object,
  ]),
  space: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.object,
  ]),
  zIndices: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.object,
  ]),
});
