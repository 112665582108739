import styled from 'styled-components';
import PanelContainer from './PanelContainer';

const InteractivePanelContainer = styled(PanelContainer)`
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.gray['050']};
  }
`;

export default InteractivePanelContainer;
