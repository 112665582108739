import styled from 'styled-components';
import { colorProperty } from 'src/mixins/common-properties';

const ProgressBar = styled.div`
  background-color: ${colorProperty};
  display: flex;
  transition: width 0.6s ease;
  width: ${props => props.size}%;
`;

ProgressBar.defaultProps = {
  color: 'primary',
};

export default ProgressBar;
