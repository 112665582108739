import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import getPosition from 'src/utils/position-helper';

import { elevationVariants, sizeVariants } from './variants';

const PanelContainer = styled(Flex)`
  background-color: ${props => props.theme.colors.white};
  ${elevationVariants};
  ${getPosition};
  max-width: ${sizeVariants};
  width: 100%;
`;

PanelContainer.defaultProps = {
  elevation: 'medium',
  position: 'static',
  top: null,
  right: null,
  bottom: null,
  left: null,
  size: 'medium',
};

export default PanelContainer;
