import styled from 'styled-components';

import { bodyTextStyle } from 'src/mixins/text-styles';

export default styled.label`
  ${bodyTextStyle};
  color: ${props => props.theme.colors.gray['600']};
  display: block;
  margin-bottom: 4px;
  margin-left: 4px;
`;
