import React from 'react';
import PropTypes from 'prop-types';

import { colorPropType } from 'src/mixins/common-properties';

import Bar from '../styles/ProgressBar';
import Container from '../styles/ProgressBarContainer';

const ProgressBar = ({
  id,
  className,
  bgColor,
  color,
  progress,
  size,
  ...props
}) => (
  <Container
    id={id}
    className={className}
    color={bgColor}
    size={size}
    {...props}
  >
    <Bar color={color} size={progress} />
  </Container>
);

ProgressBar.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  bgColor: colorPropType,
  color: colorPropType,
  progress: PropTypes.number,
  size: PropTypes.number,
};

ProgressBar.defaultProps = {
  id: null,
  className: null,
  bgColor: 'transparent',
  color: 'primary',
  progress: 0,
  size: 2,
};

export default ProgressBar;
