import styled from 'styled-components';
import tag from 'clean-tag';
import { color, space } from 'styled-system';
import extendTagBlacklist from 'src/utils/extend-tag-blacklist';

const GeneralLayoutTopSection = styled(tag).attrs(({ nav }) => ({
  blacklist: extendTagBlacklist([
    'bottomOffset',
    'fixed',
    'leftOffset',
    'nav',
    'rightOffset',
    'topOffset',
  ]),
  is: nav ? 'nav' : 'header',
}))`
  ${color};
  ${space};
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.gray['300']};
  display: flex;
  top: 0;
  z-index: 150;
  ${props =>
    props.fixed
      ? `
    left: ${props.leftOffset}px;
    position: fixed;
    right: ${props.rightOffset}px;
    top: ${props.topOffset}px;
  `
      : `
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  `};
`;

export const TopSectionSpace = styled.div`
  padding-top: 60px;
  position: relative;
  width: 100%;

  ${GeneralLayoutTopSection /* sc-sel */} {
    height: 60px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    padding-top: 112px;

    ${GeneralLayoutTopSection /* sc-sel */} {
      height: 112px;
    }
  }
`;

GeneralLayoutTopSection.propTypes = {
  ...color.propTypes,
  ...space.propTypes,
};

GeneralLayoutTopSection.defaultProps = {
  bg: 'gray.100',
  nav: false,
  pb: 3,
  pt: [3, 5],
};

export default GeneralLayoutTopSection;
