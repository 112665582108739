import styled from 'styled-components';

import CheckboxLabel from './CheckboxLabel';

const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  opacity: 0;

  &:checked + ${CheckboxLabel /* sc-sel */}::before {
    color: ${props => props.theme.colors.primary.default};
    content: ${props => (props.rounded ? "'check_circle'" : "'check_box'")};
  }

  &:disabled + ${CheckboxLabel /* sc-sel */} {
    color: ${props => props.theme.colors.gray.light};
    cursor: not-allowed;

    &::before {
      color: ${props => props.theme.colors.gray.light};
    }
  }
`;

export default CheckboxInput;
