export const NUMBER_SIZES_MAP = {
  tiny: 10,
  xsmall: 12,
  small: 14,
  medium: 18,
  large: 22,
  xlarge: 26,
  huge: 32,
  xhuge: 38,
};
export const SIZES_MAP = {
  tiny: 12,
  xsmall: 16,
  small: 20,
  medium: 24,
  large: 28,
  xlarge: 32,
  huge: 40,
  xhuge: 48,
};
export const SIZE_OPTIONS = Object.keys(SIZES_MAP);
