import styled from 'styled-components';
import { transparentize } from 'polished';
import tag from 'clean-tag';

import { colorProperty } from 'src/mixins/common-properties/color';
import extendTagBlacklist from 'src/utils/extend-tag-blacklist';
import isHex from 'src/utils/is-hex';
import iconGlyphStyle from 'src/mixins/icon-glyph-style';

import { sizeVariants, glyphSizeVariants } from './variants';

const backgroundStyles = props => {
  if (isHex(props.backgroundColor)) {
    return `background-color: ${props.backgroundColor}`;
  }

  const color = colorProperty(props, 'backgroundColor');
  return color && isHex(color)
    ? `background-color: ${transparentize(0.8, color)};`
    : `background-color: ${color};`;
};

const borderStyles = props =>
  props.borderColor
    ? `border: 1px solid ${colorProperty(props, 'borderColor')};`
    : null;

const IconGlyph = styled(tag.i).attrs({
  blacklist: extendTagBlacklist([
    'backgroundColor',
    'borderColor',
    'glyphSize',
    'interactiveColor',
  ]),
})`
  ${iconGlyphStyle};
  ${backgroundStyles};
  ${borderStyles};
  border-radius: 50%;
  color: ${colorProperty};
  font-size: ${props => glyphSizeVariants(props) || sizeVariants(props)}px;
  user-select: none;
  height: ${sizeVariants}px;
  width: ${sizeVariants}px;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;

IconGlyph.defaultProps = {
  color: 'primary',
  size: 'medium',
  glyphSize: null,
};

export default IconGlyph;
