import styled from 'styled-components';

import Icon from 'src/components/Icon';
import { variants } from 'src/utils/theme-helper';

const typeVariants = variants('type', {
  account_circle: ({ theme }) => ({
    backgroundColor: theme.colors.gray['400'],
    color: theme.colors.gray['200'],
  }),
  business: ({ theme }) => ({
    backgroundColor: theme.colors.gray['200'],
    color: theme.colors.gray['400'],
  }),
});

const AvatarIcon = styled(Icon).attrs({
  color: 'inherit',
})`
  border-radius: 100%;
  overflow: hidden;
  ${typeVariants};
`;

export default AvatarIcon;
