/* eslint-disable no-console */
export function deprecateProperty(componentName, propName, info) {
  console.warn(
    `\`${componentName}\` property \`${propName}\` marked as deprecated for next major version. ${info}`,
  );
}

export function deprecateImport(path, info) {
  console.warn(
    `\`${path}\` imports marked as deprecated for next major version. ${info}`,
  );
}
