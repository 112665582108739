import { createContext } from 'react';

const LayoutInfo = createContext({
  bottomOffset: 0,
  leftOffset: 0,
  rightOffset: 0,
  topOffset: 0,
});

export const LayoutInfoProvider = LayoutInfo.Provider;
export const LayoutInfoConsumer = LayoutInfo.Consumer;
