import styled from 'styled-components';

import transition from 'src/mixins/css-transitions';

const ContextualTextFieldInput = styled.input`
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px 0;
  transition: ${transition({ property: 'box-shadow' })};
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: normal;

  &:not(:disabled) {
    &:hover {
      box-shadow: inset 0 -1px 0 0 ${props => props.theme.colors.gray['500']};
    }

    &:focus {
      box-shadow: inset 0 -2px 0 0 ${props => props.theme.colors.primary.default};
    }
  }
`;

ContextualTextFieldInput.defaultProps = {
  maxWidth: '100%',
  minWidth: null,
};

export default ContextualTextFieldInput;
