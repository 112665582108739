import styled from 'styled-components';
import TooltipContent from './TooltipContent';

const TooltipWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-block;

  &:hover ${TooltipContent /* sc-sel */} {
    display: inline-block;
  }
`;

export default TooltipWrapper;
