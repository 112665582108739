import { createGlobalStyle } from 'styled-components';

const ResetToastStyles = createGlobalStyle`
  div.toast-container {
    align-items: center;
    bottom: 2em;
    display: flex;
    flex-direction: column;
    padding: 0;

    &.Toastify__toast-container--bottom-left {
      align-items: flex-start;
      left: 2em;
    }

    &.Toastify__toast-container--bottom-right {
      align-items: flex-end;
      right: 2em;
    }
  }

  .toast-container .toast-container__body {
    margin: 0;
    flex: unset;
  }

  .toast-container .toast-container__toast {
    background: unset;
    color: unset;
    position: relative;
    min-height: unset;
    box-sizing: unset;
    padding: 0;
    border-radius: 0;
    box-shadow: unset;
    justify-content: space-between;
    margin-bottom: 0;
    max-height: unset;
    overflow: unset;
    font-family: unset;
    cursor: pointer;
    direction: ltr;

  }

  .toast-container .toast-container__toast + .toast-container__toast {
    margin-top: 1rem;
  }
`;

export default ResetToastStyles;
