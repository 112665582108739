import { css } from 'styled-components';
import {
  bodyTextStyle,
  captionTextStyle,
  captionSmallTextStyle,
} from 'src/mixins/text-styles';

export const KINDS_MAP = {
  default: props => props.theme.colors.gray['600'],
  success: props => props.theme.colors.success.default,
  warning: props => props.theme.colors.warning.dark,
  error: props => props.theme.colors.error.default,
};
export const KIND_OPTIONS = Object.keys(KINDS_MAP);

export const SIZES_MAP = {
  tiny: css`
    ${captionSmallTextStyle};
  `,
  small: css`
    ${captionTextStyle};
  `,
  medium: css`
    ${bodyTextStyle};
  `,
  large: css`
    ${props =>
      bodyTextStyle(props, {
        fontSize: '36px',
        lineHeight: '44px',
      })};
  `,
  huge: css`
    ${props =>
      bodyTextStyle(props, {
        fontSize: '48px',
        lineHeight: '56px',
      })};
  `,
};
export const SIZE_OPTIONS = Object.keys(SIZES_MAP);
