import { variants } from 'src/utils/theme-helper';
import {
  COLOR_SCHEMES_MAP,
  BG_COLOR_SCHEMES_MAP,
  DIRECTIONS_MAP,
  SHIFT_MAP,
} from '../constants';

export const colorSchemeVariants = variants('colorScheme', COLOR_SCHEMES_MAP);
export const bgColorSchemeVariants = variants(
  'colorScheme',
  BG_COLOR_SCHEMES_MAP,
);
export const directionVariants = variants('direction', DIRECTIONS_MAP);
export const shiftVariants = variants('shift', SHIFT_MAP);
