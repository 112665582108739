import React from 'react';
import PropTypes from 'prop-types';

import * as CustomPropTypes from 'src/utils/custom-prop-types';

import Content from '../styles/CardContent';
import Extra from '../styles/CardExtra';
import Panel from '../styles/CardPanel';

const Card = ({ id, className, children }) => (
  <Panel id={id} className={className}>
    {children}
  </Panel>
);

Card.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: CustomPropTypes.elementsOf([Content, Extra]).isRequired,
};

Card.defaultProps = {
  id: null,
  className: null,
};

Card.Content = Content;
Card.Content.displayName = 'Card.Content';

Card.Extra = Extra;
Card.Extra.displayName = 'Card.Extra';

export default Card;
