import { css } from 'styled-components';

export const SIZES_MAP = {
  tiny: css`
    margin-bottom: ${props => props.theme.baselineGrid * 4}px;
    padding-bottom: ${props => props.theme.baselineGrid * 4}px;
  `,
  small: css`
    margin-bottom: ${props => props.theme.baselineGrid * 5.75}px;
    padding-bottom: ${props => props.theme.baselineGrid * 5.75}px;
  `,
  medium: css`
    margin-bottom: ${props => props.theme.baselineGrid * 9}px;
    padding-bottom: ${props => props.theme.baselineGrid * 9}px;
  `,
  large: css`
    margin-bottom: ${props => props.theme.baselineGrid * 14}px;
    padding-bottom: ${props => props.theme.baselineGrid * 14}px;
  `,
};
export const SIZE_OPTIONS = Object.keys(SIZES_MAP);
