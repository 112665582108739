import React from 'react';

const IceCreamSVG = () => (
  <svg
    width="272px"
    height="150px"
    viewBox="0 0 272 150"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g
      id="Foundation"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Illustrations" transform="translate(-1924.000000, -182.000000)">
        <g id="icecream" transform="translate(1923.000000, 182.000000)">
          <g id="icecream-shades" transform="translate(0.000000, 48.000000)">
            <path
              d="M270.277723,90.3024448 C198.22461,97.5160846 145.496784,101.122904 112.094242,101.122904 C78.6917007,101.122904 68.4728385,96.748603 81.4376554,88 C83.3705571,89.5258481 84.8739584,90.2933297 85.9478595,90.3024448 C87.0217606,90.3115599 148.465048,90.3115599 270.277723,90.3024448 Z"
              id="Path-9"
              fillOpacity="0.05"
              fill="#616161"
            />
            <path
              d="M270.277723,89.3972896 C198.22461,93.7750468 145.496784,95.9639254 112.094242,95.9639254 C85.8401971,95.9639254 74.8640144,94.9660206 77.2547687,91.6860545 C77.6887411,91.0906713 79.0830367,89.8619865 81.4376554,88 C83.3705571,88.9259947 84.8739584,89.3917579 85.9478595,89.3972896 C87.0217606,89.4028213 148.465048,89.4028213 270.277723,89.3972896 Z"
              id="Path-9"
              fillOpacity="0.05"
              fill="#616161"
            />
            <path
              d="M69.1457218,90.2964014 C58.7803488,93.0082701 48.162311,94.3642045 37.2916084,94.3642045 C25.6541811,94.3642045 18,93.0661792 18,90.2964014 C18,88.4498829 20.174997,87.7169428 24.5249909,88.097581 C26.077434,89.5634613 27.2677167,90.2964014 28.095839,90.2964014 C33.2679316,90.2964014 46.9512259,90.2964014 69.1457218,90.2964014 Z"
              id="Path-9-Copy"
              fillOpacity="0.05"
              fill="#616161"
            />
            <path
              d="M69.1457218,89.7969377 C58.7803488,91.9189793 48.162311,92.98 37.2916084,92.98 C25.6541811,92.98 18,91.9642932 18,89.7969377 C18,88.3520341 20.174997,87.7785073 24.5249909,88.0763573 C26.077434,89.2234109 27.2677167,89.7969377 28.095839,89.7969377 C33.2679316,89.7969377 46.9512259,89.7969377 69.1457218,89.7969377 Z"
              id="Path-9-Copy"
              fillOpacity="0.05"
              fill="#616161"
            />
            <path
              d="M14.7666657,87.7529226 C12.1656148,90.5837767 9.16072349,91.7474551 5.75199187,91.2439578 C0.638894444,90.4887117 0.0648935371,88.4198358 1.94761148,87.9700169 C3.20275678,87.6701377 4.37682966,87.5977729 5.46983014,87.7529226 C6.80184393,89.2972013 8.25514814,90.0693407 9.82974279,90.0693407 C11.4043374,90.0693407 13.0499784,89.2972013 14.7666657,87.7529226 Z"
              id="Path-9-Copy-3"
              fillOpacity="0.05"
              fill="#616161"
            />
            <path
              d="M14.7666657,87.7236754 C12.1656148,89.5111374 9.16072349,90.2459089 5.75199187,89.9279899 C0.638894444,89.4511113 0.0648935371,88.1447787 1.94761148,87.8607534 C3.20275678,87.6714033 4.37682966,87.6257106 5.46983014,87.7236754 C6.80184393,88.6987661 8.25514814,89.1863115 9.82974279,89.1863115 C11.4043374,89.1863115 13.0499784,88.6987661 14.7666657,87.7236754 Z"
              id="Path-9-Copy-3"
              fillOpacity="0.05"
              fill="#616161"
            />
            <path
              d="M90.7835338,70.0118674 C95.0576674,70.0118674 95.2531239,78.1292106 90.7835338,78.1292106 C87.2868062,78.1292106 85.9831754,78.1292106 86.8726416,78.1292106 C89.4456176,77.0893289 90.3638538,76.0205064 89.6273503,74.9227432 C88.522595,73.2760984 73.949722,74 68.2916084,74 C62.6334949,74 49,72.7729689 49,70.1546812 C49,68.4091561 51.2303733,67.7241792 55.69112,68.0997507 C57.1573787,69.4697044 58.3045692,70.1546812 59.1326914,70.1546812 C60.3748748,70.1546812 86.5094002,70.0118674 90.7835338,70.0118674 Z"
              id="Path-9-Copy-2"
              fillOpacity="0.05"
              fill="#616161"
            />
            <path
              d="M90.5691024,70.5971369 C93.4635026,70.5971369 94.559495,72.3595088 93.8570796,75.8842526 C93.4409472,74.667626 92.4508003,73.8023794 90.8866389,73.2885129 C88.5403967,72.5177131 74.3435508,72.7174014 68.5961245,72.7174014 C62.8486982,72.7174014 49,71.7526683 49,69.6940827 C49,68.3216922 50.7474095,67.7749188 54.2422286,68.0537625 C56.7679721,69.1473093 58.4514409,69.6940827 59.2926349,69.6940827 C60.5544261,69.6940827 86.227502,70.5971369 90.5691024,70.5971369 Z"
              id="Path-9-Copy-2"
              fillOpacity="0.05"
              fill="#616161"
            />
            <path
              d="M259.195661,90.2339197 C216.919263,85.9904815 195.781063,77.579175 195.781063,65 L187,90.2339197 L259.195661,90.2339197 Z"
              id="Path-3"
              fillOpacity="0.05"
              fill="#616161"
            />
            <path
              d="M187,54 C159.767102,78 134.733704,90 111.899806,90 C89.0659082,90 110.170065,90 175.212277,90 L187,54 Z"
              id="Path-5"
              fillOpacity="0.05"
              fill="#616161"
            />
            <path
              d="M53.8492597,63.1105933 C52.9944348,67.3390327 55.5125229,69.4532525 61.4035243,69.4532525 C67.2945256,69.4532525 77.09287,69.3439939 90.7985573,69.1254769 C81.4456998,67.1929098 73.3975827,66.2266262 66.654206,66.2266262 C59.9108293,66.2266262 55.6425139,65.1879486 53.8492597,63.1105933 Z"
              id="Path-6"
              fillOpacity="0.05"
              fill="#616161"
            />
            <path
              d="M6.09205233,82 C7.80846636,85.9328079 11.1111156,87.6222945 16,87.0684597 C13.5047029,90.0466021 10.7581728,90.7273596 7.76040968,89.1107322 C4.76264659,87.4941047 4.20652747,85.1238606 6.09205233,82 Z"
              id="Path-7"
              fillOpacity="0.05"
              fill="#616161"
            />
            <path
              d="M190.197826,70.8702689 C186.504459,80.8225292 182.052534,85.9064806 176.842051,86.1221231 L175.928802,89.9737804 L186.017955,89.9737804 L203.395652,40 C198.290469,50.627919 193.891193,60.9180087 190.197826,70.8702689 Z"
              id="Path-8"
              fillOpacity="0.05"
              fill="#616161"
            />
            <polygon
              id="Path-11"
              fillOpacity="0.05"
              fill="#616161"
              points="203.724831 41 265.710953 86.8513627 202 46.0696001"
            />
            <path
              d="M103.539896,22.1675293 C109.47018,28.4752914 112.235561,39.9285651 112.235561,46.7142377 C112.235561,51.2380194 116.771477,55.3019532 125.843309,58.906039 C109.580852,58.1853185 100.377589,54.8489783 98.2335216,48.8970183 C95.0174199,39.9690784 107.828814,29.9151261 80.5904981,11.1871988 C92.1952249,9.84639236 97.6096117,15.8597673 103.539896,22.1675293 Z"
              id="Path-13-Copy-4"
              fillOpacity="0.05"
              fill="#616161"
              transform="translate(103.216904, 34.952082) scale(-1, 1) translate(-103.216904, -34.952082) "
            />
            <path
              d="M164.925945,23.1377669 C164.925945,31.733749 154.314695,35.9808497 152.611953,34.534085 C150.909212,33.0873202 162.323139,19.5786138 135.084823,0.850686482 C157.51128,-0.255735623 164.925945,14.5417848 164.925945,23.1377669 Z"
              id="Path-13-Copy-5"
              fillOpacity="0.05"
              fill="#616161"
              transform="translate(150.005384, 17.802303) scale(-1, 1) translate(-150.005384, -17.802303) "
            />
            <path
              d="M81.2188485,83 C80.0925618,87.3021683 83.3135351,89.4532525 90.8817682,89.4532525 C98.4500013,89.4532525 107.720209,89.3439939 118.69239,89.1254769 C109.339532,87.1929098 101.291415,86.2266262 94.5480385,86.2266262 C87.8046619,86.2266262 83.3615985,85.1510842 81.2188485,83 Z"
              id="Path-6"
              fillOpacity="0.1"
              fill="#BDBDBD"
            />
            <path
              d="M24.2188485,83 C23.0925618,87.3021683 26.3135351,89.4532525 33.8817682,89.4532525 C41.4500013,89.4532525 50.7202086,89.3439939 61.6923899,89.1254769 C52.3395323,87.1929098 44.2914152,86.2266262 37.5480385,86.2266262 C30.8046619,86.2266262 26.3615985,85.1510842 24.2188485,83 Z"
              id="Path-6"
              fillOpacity="0.05"
              fill="#616161"
            />
          </g>
          <g id="iceream-shape" transform="translate(5.000000, 0.000000)">
            <g
              id="icecream"
              transform="translate(0.000000, 48.000000)"
              stroke="#BDBDBD"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            >
              <polyline
                id="cone"
                points="199.123818 39.8528832 267.029785 90.0517287 181 90.0517287"
              />
              <path
                d="M193.142685,30.9326444 L199.130591,35.6745768 C199.797022,36.2023361 200.057013,37.0937091 199.778812,37.8969932 L182.177685,88.7189657 C181.898765,89.5243268 181.140108,90.0644489 180.287816,90.0644489 L173,90.0644489 C171.895431,90.0644489 171,89.1690184 171,88.0644489 C171,87.8453655 171.035997,87.6277706 171.106551,87.4203591 L190.007588,31.8564542 C190.363309,30.8107313 191.499403,30.2513743 192.545126,30.6070952 C192.761447,30.6806804 192.963558,30.7907902 193.142685,30.9326444 Z"
                id="border"
              />
              <path
                d="M189.756588,31.8980837 L170.855552,87.4619886 C170.784997,87.6694002 170.749001,87.886995 170.749001,88.1060784 C170.749001,89.0686194 171.428962,89.8723469 172.334747,90.0631236 L82,90.0631236 C78.6862915,90.0631236 76,87.3768321 76,84.0631236 C76,80.7494151 78.6862915,78.0631236 82,78.0631236 C85.1091981,78.0561092 86.6091981,78.0457935 86.5,78.0321767 C88.4731558,77.7861277 90,76.1029324 90,74.0631236 C90,72.0233148 88.4731558,70.3401195 86.5,70.0940705 C86.6091981,70.0804537 75.7758647,70.0701381 54,70.0631236 C50.6862915,70.0631236 48,67.3768321 48,64.0631236 C48,60.7494151 50.6862915,58.0631236 54,58.0631236 L75,58.0631236 C82.904571,58.0631236 89.3810892,51.9489026 89.9581715,44.1918434 C89.8159914,40.2506676 90.3829581,36.2172606 91.7437491,32.2652366 C97.5839354,15.3041041 115.868982,6.16369184 132.874561,11.5378022 C141.425141,1.55927844 155.509934,-2.73125412 168.689611,1.80687271 C181.592427,6.24966833 189.969331,17.8944008 190.873448,30.6990797 C190.3656,30.9132759 189.948054,31.3352276 189.756588,31.8980837 Z"
                id="cream"
              />
              <path
                d="M24,78.0631236 L62,78.0631236 C65.3137085,78.0631236 68,80.7494151 68,84.0631236 C68,87.3768321 65.3137085,90.0631236 62,90.0631236 L24,90.0631236 C20.6862915,90.0631236 18,87.3768321 18,84.0631236 C18,80.7494151 20.6862915,78.0631236 24,78.0631236 Z"
                id="cream-dash"
              />
              <path
                d="M6,78.0631236 C9.3137085,78.0631236 12,80.7494151 12,84.0631236 C12,87.3768321 9.3137085,90.0631236 6,90.0631236 C2.6862915,90.0631236 4.05812251e-16,87.3768321 0,84.0631236 C4.82366169e-16,80.7494151 2.6862915,78.0631236 6,78.0631236 Z"
                id="cream-dot"
              />
              <g id="grid" transform="translate(186.000000, 49.000000)">
                <path
                  d="M61.8738681,30.8741506 L65.58199,38.4769271"
                  id="Line-Copy-4"
                />
                <path
                  d="M40.8228728,14.8744081 L52.3438696,38.4959521"
                  id="Line-Copy-5"
                />
                <path
                  d="M38.950746,38.1971732 L20.3914265,0.144929232"
                  id="Line-Copy-6"
                />
                <path
                  d="M10.0626167,6.12892474 L25.8352341,38.4675827"
                  id="Line-Copy-7"
                />
                <path
                  d="M12.6990828,38.6956754 L4.45717483,21.7972598"
                  id="Line-Copy-8"
                />
                <path
                  d="M9.400377,7.83665156 L22.6774951,1.36096838"
                  id="Line-Copy-4"
                />
                <path
                  d="M3.85495558,23.7887338 L33.556453,9.30234554"
                  id="Line-Copy-5"
                />
                <path
                  d="M0.706955358,38.5715155 L44.3041687,17.3077338"
                  id="Line-Copy-6"
                />
                <path
                  d="M27.8497333,38.5804976 L55.0503492,25.3138708"
                  id="Line-Copy-7"
                />
                <path
                  d="M65.9467326,33.246749 L54.8926027,38.6382083"
                  id="Line-Copy-8"
                />
              </g>
            </g>
            <g
              id="sparks"
              transform="translate(59.000000, 17.000000)"
              stroke="#BDBDBD"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            >
              <g
                id="illustrations/spark"
                transform="translate(16.000000, 14.000000)"
              >
                <path d="M7,4 L7,0" id="Line-3" />
                <path d="M7,14 L7,10" id="Line-3-Copy" />
                <path d="M0,7 L4,7" id="Line-3-Copy-2" />
                <path d="M10,7 L14,7" id="Line-3-Copy-3" />
              </g>
              <g
                id="illustrations/spark"
                transform="translate(70.000000, 0.000000)"
              >
                <path d="M7,4 L7,0" id="Line-3" />
                <path d="M7,14 L7,10" id="Line-3-Copy" />
                <path d="M0,7 L4,7" id="Line-3-Copy-2" />
                <path d="M10,7 L14,7" id="Line-3-Copy-3" />
              </g>
              <g
                id="illustrations/spark"
                transform="translate(0.000000, 58.000000)"
              >
                <path d="M7,4 L7,0" id="Line-3" />
                <path d="M7,14 L7,10" id="Line-3-Copy" />
                <path d="M0,7 L4,7" id="Line-3-Copy-2" />
                <path d="M10,7 L14,7" id="Line-3-Copy-3" />
              </g>
            </g>
            <g
              id="dots"
              transform="translate(33.000000, 0.000000)"
              fill="#BDBDBD"
            >
              <g
                id="illustrations/dot"
                transform="translate(70.000000, 0.000000)"
              >
                <circle id="Oval-6-Copy-2" cx="1.5" cy="1.5" r="1.5" />
              </g>
              <g
                id="illustrations/dot"
                transform="translate(54.000000, 24.000000)"
              >
                <circle id="Oval-6-Copy-2" cx="1.5" cy="1.5" r="1.5" />
              </g>
              <g
                id="illustrations/dot"
                transform="translate(81.000000, 46.000000)"
              >
                <circle id="Oval-6-Copy-2" cx="1.5" cy="1.5" r="1.5" />
              </g>
              <g
                id="illustrations/dot"
                transform="translate(56.000000, 64.000000)"
              >
                <circle id="Oval-6-Copy-2" cx="1.5" cy="1.5" r="1.5" />
              </g>
              <g
                id="illustrations/dot"
                transform="translate(16.000000, 61.000000)"
              >
                <circle id="Oval-6-Copy-2" cx="1.5" cy="1.5" r="1.5" />
              </g>
              <g
                id="illustrations/dot"
                transform="translate(0.000000, 42.000000)"
              >
                <circle id="Oval-6-Copy-2" cx="1.5" cy="1.5" r="1.5" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IceCreamSVG;
