import React from 'react';

const IllustrationSVG = () => (
  <svg width="295" height="260" viewBox="0 0 295 260">
    <defs>
      <linearGradient id="a" x1=".5%" x2="101.5%" y1="101.5%" y2="-.5%">
        <stop offset="0%" stopColor="#6800C3" />
        <stop offset="100%" stopColor="#AC71F5" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="M56 31v-4M56 41v-4M49 34h4M59 34h4" />
      </g>
      <g
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="M206 24v-4M206 34v-4M199 27h4M209 27h4" />
      </g>
      <g
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="M234 103v-4M234 113v-4M227 106h4M237 106h4" />
      </g>
      <g
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="M66 126v-4M66 136v-4M59 129h4M69 129h4" />
      </g>
      <g
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="M109 232v-4M109 242v-4M102 235h4M112 235h4" />
      </g>
      <g
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="M220 225v-4M220 235v-4M213 228h4M223 228h4" />
      </g>
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(112 20)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(57 10)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(69 67)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(78 98)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(81 140)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(60 168)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(69 203)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(93 220)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(80 249)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(174 232)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(226 242)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(217 196)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(233 168)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(219 136)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(254 87)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(224 64)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        fill="#BDBDBD"
        transform="translate(226 7)"
      />
      <g fill="#616161" fillOpacity=".05">
        <path d="M206.797 49.203A8.003 8.003 0 0 1 213 57v149a8 8 0 0 1-8 8h-98a8.003 8.003 0 0 1-7.418-5H199a8 8 0 0 0 8-8V51c0-.618-.07-1.22-.203-1.797z" />
        <path d="M206.107 47.323A7.003 7.003 0 0 1 211 54v151a7 7 0 0 1-7 7H104a6.992 6.992 0 0 1-5.745-3H199a8 8 0 0 0 8-8V51a7.966 7.966 0 0 0-.893-3.677zM101 48.5h93v1c0 6.627-5.373 12-12 12h-69c-6.627 0-12-5.373-12-12v-1zM94.5 100.604a714.598 714.598 0 0 0 14 3.036V185h-2c-6.627 0-12 5.373-12 12v-96.396zm0-21.226V55c0 6.627 5.373 12 12 12h2v15.035a714.59 714.59 0 0 0-14-2.657z" />
        <path d="M147.43 110.514l-31.191-.624-.612 3.99-9.319-1.277c-7.212-1.393-11.97-4.07-14.272-8.033l-3.16-1.153.621-3.798 27.369 5.86 30.564 5.035zM41.504 74.535l75.87 9.941-.577 9.947a4603.977 4603.977 0 0 1-39.395-8.955c-13.539-3.138-25.505-6.782-35.898-10.933z" />
        <path d="M62.351 80l55.023 7.21-.419 7.213a3338.907 3338.907 0 0 1-28.57-6.494C78.567 85.653 69.89 83.009 62.351 80z" />
        <path d="M117.417 84.312c-.38 1.578-1.434 3.278-3.161 5.1-1.727 1.82-3.809 2.829-6.245 3.023l9.178 2.075.228-10.198zM33 74.02c1.58-2.013 3.258-3.02 5.036-3.02 1.778 0 2.473 1.242 2.087 3.726l-1.596.802c-.266-.72-.922-1.223-1.966-1.508-1.044-.285-2.23-.285-3.561 0z" />
        <path d="M35 74.184C36.142 72.728 37.356 72 38.642 72s1.79.898 1.51 2.695l-1.154.58c-.193-.521-.667-.885-1.422-1.09-.755-.207-1.614-.207-2.576 0z" />
        <path d="M30.848 81.828c1.687.087 3.044-.43 4.072-1.553 1.542-1.684-1.17-1.81 1.542-3.795 2.711-1.985 2.711-1.284 2.711-.41 0 .872-.232 5.755-.886 7.044-.653 1.289-2.668 3.269-4.979 2.375-1.54-.596-2.36-1.817-2.46-3.661z" />
      </g>
      <g>
        <path
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M89 78.358V51a8 8 0 0 1 8-8h102a8 8 0 0 1 8 8v150a8 8 0 0 1-8 8H97a8 8 0 0 1-8-8V99.515"
        />
        <path
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M92 78.752V52a6 6 0 0 1 6-6h100a6 6 0 0 1 6 6v148a6 6 0 0 1-6 6H98a6 6 0 0 1-6-6v-99.878"
        />
        <path
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M185 113.296V180a8 8 0 0 1-8 8h-58a8 8 0 0 1-8-8v-74.818m0-23.606V72a8 8 0 0 1 8-8h58a8 8 0 0 1 8 8v12.935M109 188c-6.075 0-11 4.925-11 11M109 64c-6.075 0-11-4.925-11-11M187 188c6.075 0 11 4.925 11 11M187 64c6.075 0 11-4.925 11-11"
        />
        <path
          fill="#6800C3"
          d="M116.393 105.222l-3.477 50.31 60.128 8.451 18.219-78.226-72.817-10.233-.584 8.45A715.12 715.12 0 0 0 103 80.957V69c0-6.627 5.373-12 12-12h66c6.627 0 12 5.373 12 12v114c0 6.627-5.373 12-12 12h-66c-6.627 0-12-5.373-12-12v-80.553c3.868.82 8.332 1.744 13.393 2.775z"
          opacity=".1"
        />
      </g>
      <g>
        <path
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M117.137 94.46a714.715 714.715 0 0 1-76.796-19.617l.101-.478a4.147 4.147 0 0 0-.287-2.601 716.089 716.089 0 0 1 77.707 12.21l-.725 10.486z"
        />
        <path
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M117.14 94.425l1.306-18.901 72.817 10.233-18.219 78.226-60.128-8.45 3.473-50.25"
        />
        <path
          fill="#FFF"
          d="M116.156 108.957c4.282 23.078 14.185 31.165 27.914 24.467 14.198-6.699 27.37-10.816 38.961-12.534l-9.973 43.042-60.151-8.44 3.249-46.535z"
          opacity=".5"
        />
        <path
          fill="url(#a)"
          d="M4.156.957C8.438 24.035 18.34 32.122 32.07 25.424c14.198-6.699 27.37-10.816 38.961-12.534l-9.973 43.042-60.151-8.44L4.156.957z"
          opacity=".2"
          transform="translate(112 108)"
        />
        <g
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path d="M37.09 85.014a4.15 4.15 0 0 0 1.62-2.5l.845-3.973a4.162 4.162 0 0 0-1.148-3.831.998.998 0 0 1 .938-.182A716.396 716.396 0 0 0 94.6 89.638a716.11 716.11 0 0 0 52.08 10.006 4.955 4.955 0 0 1 4.073 6.012l-.165.713a5 5 0 0 1-5.628 3.818 716.155 716.155 0 0 1-52.77-10.112 716.385 716.385 0 0 1-55.1-15.061z" />
          <path d="M39.555 78.54a4.166 4.166 0 0 0-8.15-1.731l.888-4.176a4.166 4.166 0 1 1 8.149 1.732l-.887 4.176z" />
          <path d="M39.555 78.54l-.845 3.974a4.166 4.166 0 0 1-8.15-1.732l.845-3.973a4.166 4.166 0 0 1 8.15 1.732z" />
        </g>
      </g>
    </g>
  </svg>
);

export default IllustrationSVG;
