import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { variants } from 'src/utils/theme-helper';

import ButtonBase from './ButtonBase';
import { colorSchemeVariants } from './variants';

const ButtonFlat = styled(ButtonBase)`
  background-color: transparent;
  border: none;
  color: ${colorSchemeVariants};
  padding: ${variants('size', {
    small: '7px 12px',
    medium: '10px 19px',
    large: '15px 24px',
  })};

  &:hover:not(:disabled) {
    color: ${props =>
      props.light
        ? darken(0.15, colorSchemeVariants(props))
        : lighten(0.3, colorSchemeVariants(props))};
  }

  &:active:not(:disabled) {
    color: ${props =>
      darken(props.light ? 0.3 : 0.15, colorSchemeVariants(props))};
  }
`;

export default ButtonFlat;
