import React from 'react';
import PropTypes from 'prop-types';

import * as CustomPropTypes from 'src/utils/custom-prop-types';

import BadgeWrapper from '../styles/Badge';

const Badge = ({ id, className, children, color }) => (
  <BadgeWrapper id={id} className={className} color={color}>
    {children}
  </BadgeWrapper>
);

Badge.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: CustomPropTypes.hexColor,
};

Badge.defaultProps = {
  id: null,
  className: null,
  color: null,
};

Badge.displayName = 'Badge';

export default Badge;
