import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'src/components/Icon';

import QuestionSVG from '../assets/QuestionSVG';

const HelpcenterIcon = ({ id, className, onClick, ...props }) => (
  <Icon
    {...props}
    id={id}
    className={className}
    backgroundColor="#fff"
    borderColor="#cdd3d6"
    onClick={onClick}
    size="xlarge"
    type={<QuestionSVG />}
    interactive
  />
);

HelpcenterIcon.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

HelpcenterIcon.defaultProps = {
  id: null,
  className: null,
  onClick: null,
};

HelpcenterIcon.displayName = 'HelpcenterIcon';

export default HelpcenterIcon;
