import { css } from 'styled-components';
import { transparentize } from 'polished';

export const ARROW_SIZE = 5;

export const COLOR_SCHEMES_MAP = {
  blackngray: props => props.theme.colors.text,
  blacknwhite: props => props.theme.colors.text,
  whitenblack: props => props.theme.colors.white,
};

export const BG_COLOR_SCHEMES_MAP = {
  blackngray: props => props.theme.colors.gray['100'],
  blacknwhite: props => props.theme.colors.white,
  whitenblack: props => transparentize(0.4, props.theme.colors.black),
};

export const COLOR_SCHEME_OPTIONS = Object.keys(COLOR_SCHEMES_MAP);

export const DIRECTIONS_MAP = {
  top: css`
    top: ${props => -props.offset}px;
    left: 50%;
    transform: translate(-50%, -120%);

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-style: solid;
    }
  `,
  bottom: css`
    bottom: ${props => -props.offset}px;
    left: 50%;
    transform: translate(-50%, 120%);

    &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-style: solid;
    }
  `,
  right: css`
    top: 50%;
    right: ${props => -(props.offset + ARROW_SIZE)}px;
    transform: translate(100%, -50%);

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-style: solid;
    }
  `,
  left: css`
    top: 50%;
    left: ${props => -(props.offset + ARROW_SIZE)}px;
    transform: translate(-100%, -50%);

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-style: solid;
    }
  `,
};

export const DIRECTION_OPTIONS = Object.keys(DIRECTIONS_MAP);
