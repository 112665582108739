import styled from 'styled-components';

import Icon from 'src/components/Icon';

const DropdownHeaderIcon = styled(Icon).attrs({
  size: 'medium',
  glyphSize: 'small',
  color: 'muted',
  interactive: true,
})('');

export default DropdownHeaderIcon;
