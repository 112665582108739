import React from 'react';
import PropTypes from 'prop-types';
import { colorPropType } from 'src/mixins/common-properties';
import HeadingOne from './styles/HeadingOne';

const H1 = ({ children, ...props }) => (
  <HeadingOne {...props}>{children}</HeadingOne>
);

H1.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  color: colorPropType,
  noMargin: PropTypes.bool,
};

H1.defaultProps = {
  id: null,
  className: null,
  children: null,
  color: 'black',
  noMargin: false,
};

export default H1;
