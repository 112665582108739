import styled from 'styled-components';

import HintMessage from 'src/components/HintMessage/styles/HintText';

const TextFieldContainer = styled.div`
  text-align: left;
  display: inline-block;
  margin: 0 0 16px;
  width: 100%;
  position: relative;

  ${HintMessage /* sc-sel */} {
    padding-top: 6px;
  }
`;

export default TextFieldContainer;
