import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import H4 from 'src/components/H4';

import DropdownContext from './DropdownContext';
import DropdownHeaderContainer from '../styles/DropdownHeaderContainer';
import DropdownHeaderIcon from '../styles/DropdownHeaderIcon';

const DropdownHeader = ({ children, disabled, enableBack, enableClose }) => {
  const { closeMenu, goBack } = useContext(DropdownContext);
  return (
    <DropdownHeaderContainer disabled={disabled}>
      {enableBack && <DropdownHeaderIcon type="arrow_back" onClick={goBack} />}
      <H4 noMargin>{children}</H4>
      {enableClose && <DropdownHeaderIcon type="close" onClick={closeMenu} />}
    </DropdownHeaderContainer>
  );
};

DropdownHeader.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  enableBack: PropTypes.bool,
  enableClose: PropTypes.bool,
};

DropdownHeader.defaultProps = {
  disabled: false,
  enableBack: false,
  enableClose: false,
};

export default DropdownHeader;
