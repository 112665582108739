import styled from 'styled-components';
import { headingOneTextStyle } from 'src/mixins/text-styles';
import { colorProperty } from 'src/mixins/common-properties';
import { marginVariants } from './variants';

const HeadingOne = styled.h1`
  ${headingOneTextStyle};
  color: ${colorProperty};
  ${marginVariants};
`;

export default HeadingOne;
