import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// @INFO: Component based on https://github.com/smartprogress/react-relative-portal
const getPageOffset = () => ({
  x:
    window.pageXOffset !== undefined
      ? window.pageXOffset
      : (
          window.document.documentElement ||
          window.document.body.parentNode ||
          window.document.body
        ).scrollLeft,
  y:
    window.pageYOffset !== undefined
      ? window.pageYOffset
      : (
          window.document.documentElement ||
          window.document.body.parentNode ||
          window.document.body
        ).scrollTop,
});

class Portal extends Component {
  constructor(props) {
    super(props);
    this.el = window.document.createElement('div');
  }

  state = {
    right: 0,
    left: 0,
    top: 0,
    zIndex: 9999,
  };

  componentDidMount() {
    window.document.body.appendChild(this.el);

    if (this.element) {
      const rect = this.element.getBoundingClientRect();
      const pageOffset = getPageOffset();
      const top = pageOffset.y + rect.top;
      const right = window.innerWidth - rect.right - pageOffset.x;
      const left = pageOffset.x + rect.left;

      if (
        top !== this.state.top ||
        left !== this.state.left ||
        right !== this.state.right
      ) {
        this.setState({ left, top, right }); // eslint-disable-line
      }
    }
  }

  componentWillUnmount() {
    this.el.parentNode.removeChild(this.el);
  }

  renderPortal = () => {
    const positionLeftOrRight =
      this.props.horizontalPosition === 'right'
        ? { right: this.state.right }
        : { left: this.state.left };

    return (
      <div
        style={{
          position: 'absolute',
          zIndex: this.state.zIndex,
          top: this.state.top,
          ...positionLeftOrRight,
        }}
      >
        {React.Children.only(this.props.children)}
      </div>
    );
  };

  render() {
    return (
      <div
        ref={element => {
          this.element = element;
        }}
      >
        {ReactDOM.createPortal(this.renderPortal(), this.el)}
      </div>
    );
  }
}

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  horizontalPosition: PropTypes.oneOf(['left', 'right']),
};

Portal.defaultProps = {
  horizontalPosition: 'left',
};

export default Portal;
