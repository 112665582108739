import styled, { css } from 'styled-components';

import { bodyTextStyle } from 'src/mixins/text-styles';

const checkboxType = ({ undetermined }) =>
  undetermined
    ? css`
        color: ${props => props.theme.colors.primary.default};
        content: ${props =>
          props.rounded ? "'remove_circle'" : "'indeterminate_check_box'"};
      `
    : css`
        color: ${props => props.theme.colors.gray['400']};
        content: ${props =>
          props.rounded
            ? "'radio_button_unchecked'"
            : "'check_box_outline_blank'"};
      `;

const CheckboxLabel = styled.label`
  ${bodyTextStyle};
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  display: inline-block;
  overflow-wrap: normal;
  padding-left: 35px;
  position: relative;

  &::before {
    ${checkboxType};
    font-family: 'Material Icons', serif;
    font-feature-settings: 'liga';
    display: inline-block;
    font-size: 20px;
    left: 0;
    position: absolute;
  }
`;

export default CheckboxLabel;
