import React from 'react';
import { toast } from 'react-toastify';
import Toast from 'src/components/Toast';

export const TOAST_POSITION = toast.POSITION;

export const toastRaw = toast;

export const toastInfo = (message, options = {}) =>
  toast(<Toast>{message}</Toast>, options);

export const toastError = (error, options = {}) =>
  toast(<Toast colorScheme="error">{error}</Toast>, options);

export default {
  raw: toastRaw,
  info: toastInfo,
  error: toastError,
};
