import styled from 'styled-components';

import Icon from 'src/components/Icon';

const LinkIcon = styled(Icon).attrs({
  color: 'inherit',
  type: 'link',
})`
  background-color: ${props => props.theme.colors.gray['100']};
  color: ${props => props.theme.colors.gray['600']};
  position: relative;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    background-color: ${props => props.theme.colors.white};
  }
`;

LinkIcon.defaultProps = {
  glyphSize: 'medium',
  size: 'xlarge',
};

export default LinkIcon;
