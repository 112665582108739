import styled, { css } from 'styled-components';
import { padding, transitions } from 'polished';

import { bodyTextStyle, captionSmallTextStyle } from 'src/mixins/text-styles';
import transition from 'src/mixins/css-transitions';

import HintMessage from '../HintMessage/styles/HintText';
import Icon from '../Icon';

export const SelectFieldRequiredIcon = styled(Icon).attrs({
  color: 'warning',
  type: 'forward',
})`
  position: absolute;
  left: 0;
  top: 22px;
`;

export const SelectFieldContainer = styled.div`
  margin: 0 0 16px;
  padding-bottom: 4px;
  position: relative;
  text-align: left;
  width: ${props => (props.width ? props.width : '100%')};

  ${HintMessage /* sc-sel */} {
    padding-top: 6px;
  }
`;

const activeStateProps = css`
  &:hover {
    border-bottom-color: ${props => props.theme.colors.gray['500']};
  }

  &:focus {
    border-bottom-color: ${props => props.theme.colors.primary.default};
  }

  &:focus,
  &:hover:required,
  &:hover.error {
    border-bottom-width: 2px;
    margin-bottom: 0;
  }
`;

const disabledStateProps = css`
  &:disabled {
    color: ${props => props.theme.colors.gray600};
    cursor: not-allowed;
    border-bottom: 1px dashed ${props => props.theme.colors.gray['300']};
  }
`;

export const SelectFieldMenu = styled.select`
  ${bodyTextStyle};
  appearance: none;
  background: url('https://raw.githubusercontent.com/google/material-design-icons/master/navigation/2x_web/ic_arrow_drop_down_black_18dp.png');
  background-color: transparent;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 20px;
  border: 0;
  border-bottom: 1px solid ${props => props.theme.colors.gray['300']};
  border-radius: 0;
  margin-bottom: 1px;
  ${padding('3px', '25px', '3px', null)};
  ${transitions(
    transition({ property: 'border-color' }),
    transition({ property: 'padding-left' }),
  )};
  width: 100%;
  ${props => (props.disabled ? disabledStateProps : activeStateProps)};

  &.error {
    border-bottom-color: ${props => props.theme.colors.error.default};
  }

  &.empty {
    color: ${props => props.theme.colors.gray['600']};
  }

  &.empty:required {
    border-bottom-color: ${props => props.theme.colors.warning.default};
    padding-left: 22px;
  }
`;

export const SelectFieldLabel = styled.label`
  ${captionSmallTextStyle};
  color: ${props => props.theme.colors.gray['500']};
  opacity: 1;
  transition: ${transition({ property: 'opacity' })};

  ${SelectFieldMenu /* sc-sel */}.empty ~ & {
    opacity: ${props => (props.floated ? 1 : 0)};
  }

  ${SelectFieldMenu /* sc-sel */}:focus ~ & {
    color: ${props => props.theme.colors.primary.default};
  }

  ${SelectFieldMenu /* sc-sel */}.error ~ & {
    color: ${props => props.theme.colors.error.default};
  }

  ${SelectFieldMenu /* sc-sel */}:disabled ~ & {
    color: ${props => props.theme.colors.gray['600']};
  }

  ${SelectFieldMenu /* sc-sel */}.empty:required ~ & {
    color: ${props => props.theme.colors.warning.default};
  }
`;
