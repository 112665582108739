import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { colorPropType } from 'src/mixins/common-properties/color';

import IconGlyph from './styles/IconGlyph';
import InteractiveIconGlyph from './styles/InteractiveIconGlyph';
import IconNumber from './styles/IconNumber';
import { SIZE_OPTIONS } from './constants';

const Icon = ({
  id,
  className,
  type,
  active,
  ariaLabel,
  interactive,
  tooltip,
  onClick,
  ...props
}) => {
  let IconComponent;

  if (typeof type === 'number') {
    IconComponent = IconNumber;
  } else {
    IconComponent = interactive ? InteractiveIconGlyph : IconGlyph;
  }

  const classes = classNames(className, { active });

  return onClick ? (
    <IconComponent
      id={id}
      aria-label={ariaLabel}
      className={classes}
      onClick={onClick}
      role="button"
      title={tooltip}
      {...props}
    >
      {type}
    </IconComponent>
  ) : (
    <IconComponent id={id} className={classes} title={tooltip} {...props}>
      {type}
    </IconComponent>
  );
};

Icon.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
  ariaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * COLOR_OPTIONS:
   * primary
   * secondary
   * success
   * error
   * warning
   * black
   * white
   * muted
   * inherit
   * transparent
   * <function(props)>
   * <hex number>
   */
  backgroundColor: colorPropType,
  borderColor: colorPropType,
  color: colorPropType,
  glyphSize: PropTypes.oneOf(SIZE_OPTIONS),
  interactive: PropTypes.bool,
  interactiveColor: colorPropType,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func,
  /**
   * SIZE_OPTIONS:
   * tiny
   * small
   * medium
   * large
   * huge
   */
  size: PropTypes.oneOf(SIZE_OPTIONS),
  type: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

Icon.defaultProps = {
  id: null,
  className: null,
  active: false,
  ariaLabel: null,
  backgroundColor: 'transparent',
  borderColor: null,
  color: 'primary',
  glyphSize: null,
  interactive: false,
  interactiveColor: 'black',
  tooltip: null,
  onClick: null,
  size: 'medium',
};

export default Icon;
