import styled from 'styled-components';

const ContextualTextFieldHiddenSpan = styled.span`
  overflow: hidden;
  position: absolute;
  white-space: pre;
  visibility: hidden;
`;

export default ContextualTextFieldHiddenSpan;
