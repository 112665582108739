import styled from 'styled-components';
import { lighten, darken } from 'polished';
import { variants } from 'src/utils/theme-helper';

import ButtonBase from './ButtonBase';
import { normalColorSchemeVariants } from './variants';

const ButtonNormal = styled(ButtonBase)`
  background-color: ${normalColorSchemeVariants};
  border: none;
  color: ${props =>
    props.light && props.colorScheme === 'blacknwhite'
      ? props.theme.colors.black
      : props.theme.colors.white};
  ${props =>
    props.light &&
    props.disabled &&
    props.colorScheme === 'primary' &&
    'filter: opacity(45%) grayscale(100%)'};
  padding: ${variants('size', {
    small: '7px 12px',
    medium: '10px 19px',
    large: '15px 24px',
  })};

  &:hover:not(:disabled) {
    background-color: ${props =>
      lighten(0.15, normalColorSchemeVariants(props))};
  }

  &:active:not(:disabled) {
    background-color: ${props =>
      darken(0.15, normalColorSchemeVariants(props))};
  }
`;

export default ButtonNormal;
