import React from 'react';
import PropTypes from 'prop-types';
import { colorPropType } from 'src/mixins/common-properties';
import HeadingTwo from './styles/HeadingTwo';

const H2 = ({ children, ...props }) => (
  <HeadingTwo {...props}>{children}</HeadingTwo>
);

H2.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  color: colorPropType,
  noMargin: PropTypes.bool,
};

H2.defaultProps = {
  id: null,
  className: null,
  children: null,
  color: 'black',
  noMargin: false,
};

export default H2;
