import styled, { css } from 'styled-components';
import { transitions } from 'polished';

import transition from 'src/mixins/css-transitions';

const activeStateProps = css`
  &:hover {
    border-bottom-color: ${props => props.theme.colors.gray['500']};
  }

  &:focus {
    border-bottom-color: ${props => props.theme.colors.primary.default};
  }

  &:focus,
  &:hover:required.empty,
  &:hover.error {
    border-bottom-width: 2px;
    margin-bottom: 0;
  }
`;

const disabledStateProps = css`
  &:disabled {
    color: ${props => props.theme.colors.gray['600']};
    cursor: not-allowed;
    border-bottom: 1px dashed ${props => props.theme.colors.gray['300']};
  }
`;

const TextInput = styled.input`
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 3px 0;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.gray['300']};
  ${transitions(
    transition({ property: 'border-color' }),
    transition({ property: 'padding-left' }),
  )};

  &::placeholder {
    color: ${props => props.theme.colors.gray['600']};
  }

  ${props => (props.disabled ? disabledStateProps : activeStateProps)};

  &.error {
    border-bottom-color: ${props => props.theme.colors.error.default};
  }

  &.empty:required {
    border-bottom-color: ${props => props.theme.colors.warning.default};
    padding-left: ${props => (props.heading ? 26 : 22)}px;
  }
`;

export default TextInput;
