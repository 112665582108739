import styled from 'styled-components';
import { textAlign } from 'styled-system';
import tag from 'clean-tag';
import { textStyleProperty } from 'src/mixins/common-properties';

import HiddenSpan from './ContextualTextFieldHiddenSpan';
import Input from './ContextualTextFieldInput';

const displayProperty = props =>
  props.maxWidth !== '100%' && `display: inline-block;`;

const ContextualTextFieldContainer = styled(tag)`
  ${displayProperty};
  max-width: 100%;
  position: relative;
  ${textAlign};

  ${HiddenSpan /* sc-sel */}, ${Input /* sc-sel */} {
    max-width: ${props => props.maxWidth};
    min-width: ${props => props.minWidth};
    ${textAlign};
    ${textStyleProperty};
  }
`;

export default ContextualTextFieldContainer;
