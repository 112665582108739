import React, { cloneElement, Children, Fragment } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';

import Icon from 'src/components/Icon';

import PopoverWrapper from './styles/PopoverWrapper';
import PopoverContent from './styles/PopoverContent';

class Popover extends React.Component {
  state = {
    open: false,
  };

  togglePopover = () => this.setState(({ open }) => ({ open: !open }));

  closePopover = () => this.setState({ open: false });

  render() {
    const { open } = this.state;
    const { icon, width } = this.props;
    let { children } = this.props;

    if (isFunction(children)) {
      children = children({
        closePopover: this.closePopover,
      });

      if (children.type === Fragment) {
        children = children.props.children; // eslint-disable-line
      }
    }

    this.toggleElement = cloneElement(icon, {
      onClick: this.togglePopover,
      active: open,
    });
    this.children = Children.map(children, child =>
      cloneElement(child, {
        closePopover: this.closePopover,
        width,
      }),
    );

    return (
      <PopoverWrapper>
        {this.toggleElement}
        {open && this.children}
      </PopoverWrapper>
    );
  }
}

Popover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  icon: PropTypes.element,
  width: PropTypes.string,
};

Popover.defaultProps = {
  icon: (
    <Icon
      ariaLabel="toggle Popover"
      color="muted"
      glyphSize="small"
      size="xlarge"
      type="help"
      interactive
    />
  ),
  width: '250px',
};

Popover.Content = PopoverContent;
Popover.Header = PopoverContent.Header;

Popover.displayName = 'Popover';

export default Popover;
