import styled from 'styled-components';
import PropTypes from 'prop-types';
import tag from 'clean-tag';

import extendTagBlacklist from 'src/utils/extend-tag-blacklist';

const DetailLayoutContent = styled(tag).attrs(({ main }) => ({
  blacklist: extendTagBlacklist('main'),
  is: main ? 'main' : 'section',
}))`
  background-color: ${props => props.theme.colors.gray['100']};
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    overflow-y: auto;
  }
`;

DetailLayoutContent.propTypes = {
  main: PropTypes.bool,
};

DetailLayoutContent.defaultProps = {
  main: false,
};

export default DetailLayoutContent;
