import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SelectField from 'src/__next__/components/SelectField';
import FieldBadge from 'src/styles/FieldBadge';
import HintMessage from 'src/components/HintMessage';

import DateFieldContainer from '../styles/DateFieldContainer';
import DateFieldLabel from '../styles/DateFieldLabel';
import DateFieldWrapper from '../styles/DateFieldWrapper';
import { dates, createDays, createYears, validateDate } from '../utils';

const supportedDateTypes = ['DD-MM-YYYY', 'MM-YYYY', 'YYYY'];

class DateField extends Component {
  constructor(props) {
    super(props);
    const { format, value: originalValue } = this.props;
    const value = originalValue ? new Date(originalValue) : null;

    this.state = {
      day: value ? `${value.getUTCDate()}` : null,
      month: value ? `${value.getUTCMonth()}` : null,
      year: value ? `${value.getUTCFullYear()}` : null,
    };

    if (!supportedDateTypes.includes(format)) {
      throw new Error(`Format is not supported!`);
    }
  }

  handleBlur = () => {
    const { format, name, onBlur } = this.props;
    const { day, month, year } = this.state;
    const date = validateDate({ format, ...this.state })
      ? Date.UTC(year, month, day)
      : null;

    if (onBlur) {
      onBlur(name, date);
    }
  };

  handleChange = ({ field, value }) => {
    this.setState({ [field]: value }, () => {
      const { day, month, year } = this.state;
      const { format, name, onChange } = this.props;

      const date = validateDate({ format, ...this.state })
        ? Date.UTC(year, month, day)
        : null;

      if (onChange) {
        onChange(name, date);
      }
    });
  };

  updateDay = e => {
    this.handleChange({ field: 'day', value: e.target.value });
  };

  updateMonth = e => {
    this.handleChange({ field: 'month', value: e.target.value });
  };

  updateYear = e => {
    this.handleChange({ field: 'year', value: e.target.value });
  };

  render() {
    const {
      id,
      className,
      disabled,
      error,
      format,
      hint,
      label,
      minYear,
      maxYear,
      name,
      required,
      showBadge,
    } = this.props;
    const { day, month, year } = this.state;

    return (
      <DateFieldContainer id={id} className={classNames({ className, error })}>
        {label && (
          <DateFieldLabel htmlFor={`${name}-input`}>
            {label}
            {showBadge && !required && <FieldBadge>Optional</FieldBadge>}
          </DateFieldLabel>
        )}
        <DateFieldWrapper id={`${name}-input`} name={name}>
          {format.includes('DD') && (
            <SelectField
              ariaLabel={dates.day.label}
              disabled={disabled}
              error={error}
              name={`${name}-${dates.day.name}`}
              onBlur={this.handleBlur}
              onChange={this.updateDay}
              options={createDays({ month, year })}
              placeholder={dates.day.label}
              required={required}
              value={day}
            />
          )}
          {format.includes('MM') && (
            <SelectField
              ariaLabel={dates.month.label}
              disabled={disabled}
              error={error}
              name={`${name}-${dates.month.name}`}
              onBlur={this.handleBlur}
              onChange={this.updateMonth}
              options={dates.month.options}
              placeholder={dates.month.label}
              required={required}
              value={month}
            />
          )}
          {format.includes('YYYY') && (
            <SelectField
              ariaLabel={dates.year.label}
              disabled={disabled}
              error={error}
              name={`${name}-${dates.year.name}`}
              onBlur={this.handleBlur}
              onChange={this.updateYear}
              options={createYears({ minYear, maxYear })}
              placeholder={dates.year.label}
              required={required}
              value={year}
            />
          )}
        </DateFieldWrapper>
        {hint && <HintMessage size="small">{hint}</HintMessage>}
        {error && (
          <HintMessage size="small" kind="error">
            {error}
          </HintMessage>
        )}
      </DateFieldContainer>
    );
  }
}

DateField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.node,
  format: PropTypes.oneOf(supportedDateTypes),
  hint: PropTypes.node,
  label: PropTypes.node,
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  showBadge: PropTypes.bool,
  value: PropTypes.number,
};

DateField.defaultProps = {
  id: null,
  className: null,
  disabled: false,
  error: null,
  format: 'DD-MM-YYYY',
  hint: null,
  label: null,
  minYear: null,
  maxYear: null,
  onBlur: null,
  onChange: null,
  required: false,
  showBadge: true,
  value: null,
};

export default DateField;
