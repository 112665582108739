import styled from 'styled-components';

import SelectFieldContainer from 'src/__next__/components/SelectField/styles/SelectFieldContainer';

const DateFieldWrapper = styled.div`
  display: grid;
  grid-column-gap: ${props => props.theme.space[2]}px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;

  ${SelectFieldContainer /* sc-sel */} {
    margin-bottom: ${props => props.theme.space[0]}px;
  }
`;

export default DateFieldWrapper;
