import React from 'react';

const ApproveFlagSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <title>Approve flag</title>
    <desc>Flag with a check icon in the middle</desc>
    <g fill="none" fillRule="evenodd">
      <path id="flag" d="M0 0h20v15H2v5H0z" />
      <path
        id="proof-icon"
        d="M8.975 9.65L14.625 4l.75.75-6.4 6.4L6 8.175l.75-.75z"
      />
    </g>
  </svg>
);

export default ApproveFlagSVG;
