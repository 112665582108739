import styled from 'styled-components';

const GeneralLayoutReference = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
  ${props =>
    props.limited &&
    `
    max-width: 1200px;
    margin: auto;
  `};
`;

export default GeneralLayoutReference;
