/* eslint-disable react/no-unused-state */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';

import Bottom from './GeneralLayoutBottom';
import Container from '../styles/GeneralLayoutContainer';
import Content from '../styles/GeneralLayoutContent';
import Reference from '../styles/GeneralLayoutReference';
import { LayoutInfoProvider } from '../utils/layout-info';
import Top from './GeneralLayoutTop';

class GeneralLayout extends Component {
  constructor(props) {
    super(props);

    this.container = createRef();
    this.reference = createRef();
    this.state = {
      bottomOffset: 0,
      leftOffset: 0,
      rightOffset: 0,
      topOffset: 0,
    };
  }

  handleResize = () => {
    const { current: containerElem } = this.container;
    const { current: referenceElem } = this.reference;
    const {
      bottom: bottomOffset,
      top: topOffset,
    } = containerElem.getBoundingClientRect();
    const { left: leftOffset } = referenceElem.getBoundingClientRect();
    const {
      clientHeight,
      clientWidth,
      offsetWidth,
      scrollHeight,
    } = containerElem;
    const hasScrollbar = scrollHeight > clientHeight;
    const scrollbarSize = hasScrollbar ? offsetWidth - clientWidth : 0;

    this.setState({
      bottomOffset,
      leftOffset,
      rightOffset:
        (offsetWidth - referenceElem.offsetWidth + scrollbarSize) / 2,
      topOffset,
    });
  };

  render() {
    const { id, className, children, limited } = this.props;

    return (
      <LayoutInfoProvider value={this.state}>
        <ReactResizeDetector
          onResize={this.handleResize}
          refreshMode="throttle"
          refreshRate={100}
          handleHeight
          handleWidth
        />
        <Container id={id} className={className} ref={this.container}>
          <Reference limited={limited} ref={this.reference}>
            {children}
          </Reference>
        </Container>
      </LayoutInfoProvider>
    );
  }
}

GeneralLayout.Bottom = Bottom;
GeneralLayout.Bottom.displayName = 'GeneralLayout.Bottom';

GeneralLayout.Content = Content;
GeneralLayout.Content.displayName = 'GeneralLayout.Content';

GeneralLayout.Top = Top;
GeneralLayout.Top.displayName = 'GeneralLayout.Top';

GeneralLayout.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  /**
   * CHILDREN_OPTIONS:
   * GeneralLayout.Top
   * GeneralLayout.Content
   * GeneralLayout.Bottom
   */
  children: PropTypes.node.isRequired,
  limited: PropTypes.bool,
};

GeneralLayout.defaultProps = {
  id: null,
  className: null,
  limited: false,
};

export default GeneralLayout;
