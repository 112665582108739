import styled from 'styled-components';

import DateFieldLabel from './DateFieldLabel';

const DateFieldContainer = styled.div`
  display: inline-block;
  margin: 0 0 ${props => props.theme.space[3]}px;
  position: relative;
  text-align: left;
  width: ${props => (props.width ? props.width : '100%')};

  &.error {
    ${DateFieldLabel /* sc-sel */} {
      color: ${props => props.theme.colors.error.default};
    }
  }
`;

export default DateFieldContainer;
