import styled from 'styled-components';
import Textarea from 'react-textarea-autosize';

import { bodySerifTextStyle } from 'src/mixins/text-styles';

import TextInput from './TextInput';

const TextAreaInput = styled(TextInput).attrs({
  as: Textarea,
})`
  ${bodySerifTextStyle};
  overflow: hidden;
`;

export default TextAreaInput;
