import styled from 'styled-components';
import {
  alignItems,
  display,
  flex,
  flexDirection,
  height,
  justifyContent,
  space,
} from 'styled-system';
import tag from 'clean-tag';

const FlexAlignLeft = styled(tag)`
  ${alignItems};
  ${display};
  ${flex};
  ${flexDirection};
  ${height};
  ${justifyContent};
  ${space};
  margin-right: auto;
`;

FlexAlignLeft.propTypes = {
  ...alignItems.propTypes,
  ...display.propTypes,
  ...flex.propTypes,
  ...flexDirection.propTypes,
  ...height.propTypes,
  ...justifyContent.propTypes,
  ...space.propTypes,
};

FlexAlignLeft.defaultProps = {
  alignItems: 'center',
  display: 'flex',
  flex: '0 1 auto',
  justifyContent: 'center',
};

export default FlexAlignLeft;
