import styled from 'styled-components';
import PropTypes from 'prop-types';

const CardExtra = styled.div`
  display: none;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    display: block;
    flex: 0 1 ${props => (props.large ? 64 : 32)}px;
  }
`;

CardExtra.propTypes = {
  large: PropTypes.bool,
};

export default CardExtra;
