import { keyframes, createGlobalStyle } from 'styled-components';

export const slideIn = keyframes`
  0% {
    opacity: 0.01;
    transform: translate3d(0, 110%, 0);
    transition: opacity 300ms ease-out, transform 300ms ease-out;
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
    transition: opacity 100ms ease-in;
  }

  100% {
    opacity: 0.01;
  }
`;

const ToastAnimations = createGlobalStyle`
  .toast-container .slidein-fadeout-enter {
    animation-name: ${slideIn};
  }

  .toast-container .slidein-fadeout-exit {
    animation-name: ${fadeOut};
  }
`;

export default ToastAnimations;
