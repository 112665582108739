import { css } from 'styled-components';

export const MARGIN_MAP = {
  true: `
    margin: 0;
  `,
  false: css`
    margin: ${props => `0 0 ${7 * props.theme.baselineGrid}px 0`};
  `,
};
