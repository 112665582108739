import styled from 'styled-components';
import { colorProperty } from 'src/mixins/common-properties';
import { headingFourTextStyle } from 'src/mixins/text-styles';
import { marginVariants } from './variants';

const HeadingFour = styled.h4`
  ${headingFourTextStyle};
  color: ${colorProperty};
  ${marginVariants};
`;

export default HeadingFour;
