import { textStyle } from './utils';

export const headingOneTextStyle = (props, customValues) =>
  textStyle({
    fontFamily: props.theme.fontFamilies.sansSerif,
    fontSize: `${props.theme.fontSizeNames.h1}px`,
    fontWeight: props.theme.fontWeights.sansSerif.normal,
    letterSpacing: props.theme.letterSpacings[4],
    lineHeight: `${props.theme.lineHeights[5]}px`,
    ...customValues,
  });

export const printHeadingOneTextStyle = (props, customValues) =>
  headingOneTextStyle(props, {
    fontSize: `${props.theme.fontSizeNames.print.h1}px`,
    letterSpacing: props.theme.letterSpacings[1],
    lineHeight: `${props.theme.lineHeights[3]}px`,
    ...customValues,
  });

export const headingTwoTextStyle = (props, customValues) =>
  headingOneTextStyle(props, {
    fontSize: `${props.theme.fontSizeNames.h2}px`,
    letterSpacing: props.theme.letterSpacings[5],
    lineHeight: `${props.theme.lineHeights[4]}px`,
    ...customValues,
  });

export const printHeadingTwoTextStyle = (props, customValues) =>
  headingTwoTextStyle(props, {
    fontSize: `${props.theme.fontSizeNames.print.h2}px`,
    lineHeight: `${props.theme.lineHeights[1]}px`,
    ...customValues,
  });

export const headingThreeTextStyle = (props, customValues) =>
  headingOneTextStyle(props, {
    fontSize: `${props.theme.fontSizeNames.h3}px`,
    fontWeight: 'normal',
    letterSpacing: props.theme.letterSpacings[7],
    lineHeight: `${props.theme.lineHeights[2]}px`,
    textTransform: 'uppercase',
    ...customValues,
  });

export const printHeadingThreeTextStyle = (props, customValues) =>
  headingThreeTextStyle(props, {
    fontSize: `${props.theme.fontSizeNames.print.h3}px`,
    letterSpacing: props.theme.letterSpacings[4],
    lineHeight: `${props.theme.lineHeights[0]}px`,
    ...customValues,
  });

export const headingFourTextStyle = (props, customValues) =>
  headingOneTextStyle(props, {
    fontSize: `${props.theme.fontSizeNames.h4}px`,
    fontWeight: 'normal',
    letterSpacing: props.theme.letterSpacings[5],
    lineHeight: `${props.theme.lineHeights[2]}px`,
    ...customValues,
  });
