import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';

import { oneOrMoreOfType } from 'src/utils/custom-prop-types';

import DropdownContentContainer from '../styles/DropdownContentContainer';
import DropdownContext from './DropdownContext';

const DropdownContent = props => {
  const context = useContext(DropdownContext);
  let { children } = props;

  if (isFunction(children)) {
    children = children(context);
  }

  return <DropdownContentContainer>{children}</DropdownContentContainer>;
};

DropdownContent.propTypes = {
  children: oneOrMoreOfType([PropTypes.element, PropTypes.func, PropTypes.node])
    .isRequired,
};

export default DropdownContent;
