import styled from 'styled-components';
import HintMessage from 'src/components/HintMessage';

const CharAmount = styled(HintMessage).attrs(({ showWarning }) => ({
  kind: showWarning ? 'warning' : 'default',
  size: 'tiny',
}))`
  ${({ float }) => float && `float: ${float}`};
  padding-top: 6px;
`;

export default CharAmount;
