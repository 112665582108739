import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames';
import 'react-toastify/dist/ReactToastify.css';

import { TOAST_POSITION } from 'src/utils/toast-notifications';
import { SlideInFadeOut } from '../utils/transitions';
import ResetToastStyles from '../utils/ResetToastStyles';
import ToastAnimations from '../utils/ToastAnimations';

const Toaster = ({ id, className, ...props }) => (
  <Fragment>
    <ResetToastStyles />
    <ToastAnimations />
    <ToastContainer
      id={id}
      className={classNames(className, 'toast-container')}
      toastClassName="toast-container__toast"
      bodyClassName="toast-container__body"
      autoClose={2000}
      closeButton={false}
      draggable={false}
      newestOnTop={false}
      pauseOnFocusLoss={false}
      position={TOAST_POSITION.BOTTOM_CENTER}
      transition={SlideInFadeOut}
      hideProgressBar
      {...props}
    />
  </Fragment>
);

Toaster.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};

Toaster.defaultProps = {
  id: null,
  className: null,
};

Toaster.displayName = 'Toaster';

export default Toaster;
