export const TYPE_OPTIONS = [
  'date',
  'email',
  'number',
  'password',
  'tel',
  'text',
  'textarea',
  'url',
];
