import styled from 'styled-components';
import { space } from 'styled-system';
import tag from 'clean-tag';

const CardContent = styled(tag)`
  flex: 1 0;
  position: relative;
  ${space};
`;

CardContent.propTypes = {
  ...space.propTypes,
};

CardContent.defaultProps = {
  px: [0, 5],
};

export default CardContent;
