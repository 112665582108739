import styled from 'styled-components';
import { bodyTextStyle } from 'src/mixins/text-styles';

const FieldLabel = styled.label`
  ${bodyTextStyle};
  color: ${props => props.theme.colors.gray['600']};
  margin-bottom: ${props => props.theme.space[1]}px;
  margin-left: ${props => props.theme.space[1]}px;
`;

export default FieldLabel;
