import React from 'react';

const RejectFlagSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <title>Reject flag</title>
    <desc>Flag with a close icon in the middle</desc>
    <g fill="none" fillRule="evenodd">
      <path id="flag" d="M0 0h20v15H2v5H0z" />
      <path
        id="proof-icon"
        d="M14.45 4.75l-2.975 2.975L14.45 10.7l-.75.75-2.975-2.975L7.75 11.45 7 10.7l2.975-2.975L7 4.75 7.75 4l2.975 2.975L13.7 4z"
      />
    </g>
  </svg>
);

export default RejectFlagSVG;
