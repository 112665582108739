import React from 'react';
import PropTypes from 'prop-types';

import AlignCenter from 'src/styles/FlexAlignCenter';
import AlignLeft from 'src/styles/FlexAlignLeft';
import AlignRight from 'src/styles/FlexAlignRight';
import { LayoutInfoConsumer } from '../utils/layout-info';
import TopSection, { TopSectionSpace } from '../styles/GeneralLayoutTopSection';

const GeneralLayoutTop = props => (
  <LayoutInfoConsumer>
    {layoutInfo => (
      <TopSectionSpace>
        <TopSection {...props} {...layoutInfo} />
      </TopSectionSpace>
    )}
  </LayoutInfoConsumer>
);

GeneralLayoutTop.propTypes = {
  children: PropTypes.node,
  fixed: PropTypes.bool,
  nav: PropTypes.bool,
};

GeneralLayoutTop.defaultProps = {
  children: null,
  fixed: false,
  nav: false,
};

GeneralLayoutTop.AlignCenter = AlignCenter;
GeneralLayoutTop.AlignLeft = AlignLeft;
GeneralLayoutTop.AlignRight = AlignRight;

export default GeneralLayoutTop;
