import styled from 'styled-components';
import IconGlyph from 'src/components/Icon/styles/IconGlyph';

import { heightSizeVariants, widthSizeVariants } from './variants';

const IconGroupMerged = styled.div`
  position: relative;
  z-index: 1;

  > ${IconGlyph} {
    border-radius: 50% / 58.5%;
    height: ${heightSizeVariants}px;
    position: relative;
    width: ${widthSizeVariants}px;
    z-index: 0;

    &:hover {
      z-index: 1;
    }

    &.active {
      z-index: 2;
    }

    :first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    :last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    }

    :not(:first-child):not(:last-child) {
      border-radius: 0;
      margin-left: -1px;
    }
  }

  > *:not(${IconGlyph /* sc-sel */}) {
    ${IconGlyph} {
      border-radius: 50% / 58.5%;
      height: ${heightSizeVariants}px;
      position: relative;
      width: ${widthSizeVariants}px;
      z-index: 0;
    }

    & ${IconGlyph}:hover {
      z-index: 1;
    }

    & ${IconGlyph /* sc-sel */}.active {
      z-index: 2;
    }

    &:first-child {
      ${IconGlyph} {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child {
      ${IconGlyph} {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px;
      }
    }

    :not(:first-child):not(:last-child) {
      ${IconGlyph} {
        border-radius: 0;
        margin-left: -1px;
      }
    }
  }
`;

export default IconGroupMerged;
