import React from 'react';
import PropTypes from 'prop-types';

import { SIZE_OPTIONS, COLOR_SCHEME_OPTIONS } from './constants';
import ButtonNormal from './styles/ButtonNormal';
import ButtonFlat from './styles/ButtonFlat';
import ButtonOutlined from './styles/ButtonOutlined';

const Button = ({ children, flat, outlined, ...props }) =>
  (flat && <ButtonFlat {...props}>{children}</ButtonFlat>) ||
  (outlined && <ButtonOutlined {...props}>{children}</ButtonOutlined>) || (
    <ButtonNormal {...props}>{children}</ButtonNormal>
  );

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  colorScheme: PropTypes.oneOf(COLOR_SCHEME_OPTIONS),
  disabled: PropTypes.bool,
  flat: PropTypes.bool,
  light: PropTypes.bool,
  outlined: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(SIZE_OPTIONS),
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
};

Button.defaultProps = {
  id: null,
  className: null,
  colorScheme: 'primary',
  disabled: false,
  flat: false,
  light: false,
  outlined: false,
  onClick: null,
  size: 'medium',
  type: 'button',
};

export default Button;
