import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import get from 'lodash.get';
import merge from 'lodash.merge';

import picterTheme from 'src/themes/picter';
import { oneOrMoreOfType } from 'src/utils/custom-prop-types';

import GlobalStyles from '../GlobalStyles';
import ModernNormalize from '../ModernNormalize';
import WebFontLoader from '../WebFontLoader';

const PrismaProvider = ({ children, mode, theme }) => {
  let t = Array.isArray(theme) ? merge({}, ...theme) : theme;

  if (t.colors.__new_theme__) {
    t = merge({}, t, {
      mode,
      colors: {
        ...t.colors,
        __new_theme__: get(
          t.colors.__new_theme__.modes,
          mode,
          t.colors.__new_theme__,
        ),
      },
    });
  }

  return (
    <ThemeProvider theme={t}>
      <Fragment>
        <GlobalStyles />
        <ModernNormalize />
        <WebFontLoader>{children}</WebFontLoader>
      </Fragment>
    </ThemeProvider>
  );
};

PrismaProvider.defaultProps = {
  children: null,
  mode: 'light',
  theme: picterTheme,
};

PrismaProvider.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.string,
  theme: oneOrMoreOfType([PropTypes.object]),
};

export default PrismaProvider;
