import styled from 'styled-components';

import { ratioVariants } from './variants';

const calculateRatio = ({ ratio }) => {
  const [width, height] = ratio.split(':');
  return `${(height / width) * 100}%`; // eslint-disable-line
};

const ProportionalBox = styled.div`
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ${props => ratioVariants(props) || calculateRatio(props)};
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;

export default ProportionalBox;
