import { textStyle } from './utils';

export const actionTextStyle = (props, customValues) =>
  textStyle({
    fontFamily: props.theme.fontFamilies.sansSerif,
    fontSize: `${props.theme.fontSizeNames.action.normal}px`,
    fontWeight: props.theme.fontWeights.sansSerif.bold,
    letterSpacing: props.theme.letterSpacings[7],
    lineHeight: `${props.theme.lineHeights[0]}px`,
    textTransform: 'uppercase',
    ...customValues,
  });

export const actionLargeTextStyle = (props, customValues) =>
  actionTextStyle(props, {
    fontSize: `${props.theme.fontSizeNames.action.large}px`,
    lineHeight: `${props.theme.lineHeights[1]}px`,
    ...customValues,
  });
