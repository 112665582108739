import PropTypes from 'prop-types';
import isFunction from 'lodash.isfunction';
import * as CustomPropTypes from 'src/utils/custom-prop-types';
import { variants } from 'src/utils/theme-helper';

const COLORS_MAP = {
  primary: ({ theme }) => theme.colors.primary.default,
  secondary: ({ theme }) => theme.colors.primary.fallback,
  success: ({ theme }) => theme.colors.success.default,
  error: ({ theme }) => theme.colors.error.default,
  warning: ({ theme }) => theme.colors.warning.default,
  black: ({ theme }) => theme.colors.black,
  white: ({ theme }) => theme.colors.white,
  muted: ({ theme }) => theme.colors.gray['600'],
  inherit: 'inherit',
  transparent: 'transparent',
};
export const colorOptions = Object.keys(COLORS_MAP);

export const colorProperty = (props, propName = 'color') => {
  const prop = props[propName];
  let color;

  if (isFunction(prop)) {
    color = prop(props);
  } else {
    color =
      variants('color', COLORS_MAP)({
        ...props,
        color: prop,
      }) ||
      prop ||
      'initial';
  }
  return color;
};

export const colorPropType = PropTypes.oneOfType([
  PropTypes.oneOf(colorOptions),
  PropTypes.func,
  CustomPropTypes.hexColor,
]);
