import styled from 'styled-components';
import { headingTwoTextStyle } from 'src/mixins/text-styles';
import { colorProperty } from 'src/mixins/common-properties';
import { marginVariants } from './variants';

const HeadingTwo = styled.h2`
  ${headingTwoTextStyle};
  color: ${colorProperty};
  ${marginVariants};
`;

export default HeadingTwo;
