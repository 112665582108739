import styled from 'styled-components';
import { transparentize } from 'polished';

const ModalOverlay = styled.div`
  background-color: ${props => transparentize(0.75, props.theme.colors.black)};
  display: block;
  height: 100%;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${props => props.theme.zIndexes.modal.overlay};
`;

export default ModalOverlay;
