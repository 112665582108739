import styled from 'styled-components';
import { transparentize, transitions } from 'polished';

import { bodyTextStyle } from 'src/mixins/text-styles';
import transition from 'src/mixins/css-transitions';

const animationSettings = property => ({
  duration: '200ms',
  property,
});

const SelectFieldMenu = styled.select`
  ${bodyTextStyle};
  appearance: none;
  background-color: ${props => props.theme.colors.gray['100']};
  border: 1px solid ${props => props.theme.colors.gray['100']};
  border-radius: 2px;
  color: ${props => props.theme.colors.black};
  margin: 0;
  width: 100%;
  ${transitions(
    transition(animationSettings('color')),
    transition(animationSettings('background-color')),
    transition(animationSettings('border-color')),
    transition(animationSettings('padding-left')),
  )};

  &:hover {
    border-color: ${props => props.theme.colors.gray['300']};
  }

  &:focus {
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.primary.default};
    box-shadow: 0 0 6px 0
      ${props => transparentize(0.8, props.theme.colors.primary.default)};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.gray['400']};
    cursor: not-allowed;
  }

  &.error {
    border-color: ${props => props.theme.colors.error.default};
    background-color: ${props =>
      transparentize(0.9, props.theme.colors.error.light)};

    :focus {
      background-color: ${props => props.theme.colors.white};
      box-shadow: 0 0 6px 0
        ${props => transparentize(0.8, props.theme.colors.error.default)};
    }
  }

  &.empty {
    color: ${props => props.theme.colors.gray['600']};
  }
`;

export default SelectFieldMenu;
