// 36em - 576px
// 48em - 768px
// 62em - 992px
// 75em - 1.200px
// 88em - 1.408px
export const breakpoints = ['36em', '48em', '62em', '75em', '88em'];
export const mediaQueries = {
  biggerThan: {
    mobile: `@media screen and (min-width: ${breakpoints[0]})`,
    phablet: `@media screen and (min-width: ${breakpoints[1]})`,
    tablet: `@media screen and (min-width: ${breakpoints[2]})`,
    desktop: `@media screen and (min-width: ${breakpoints[3]})`,
    desktopHd: `@media screen and (min-width: ${breakpoints[4]})`,
  },
  for: {
    mobile: `@media screen and (max-width: ${breakpoints[0]})`,
    phablet: `@media screen and (max-width: ${breakpoints[1]})`,
    tablet: `@media screen and (max-width: ${breakpoints[2]})`,
    desktop: `@media screen and (max-width: ${breakpoints[3]})`,
    desktopHd: `@media screen and (max-width: ${breakpoints[4]})`,
  },
};

// typography
export const baseFontSizes = 16;
export const fontSizes = {
  __new_theme__: [
    baseFontSizes * 0.75, // 0 – 12px
    baseFontSizes * 0.875, // 1 –  14px
    baseFontSizes * 1, // 2 –  16px (base)
    baseFontSizes * 1.125, // 3 –  18px
    baseFontSizes * 1.25, // 4 –  20px
    baseFontSizes * 1.5, // 5 –  24px
    baseFontSizes * 2.25, // 6 –  36px
    baseFontSizes * 3.25, // 7 –  52px
    baseFontSizes * 4.5, // 8 –  72px
  ],
};

export const fonts = {
  icons: 'Material Icons',
  sansSerif: 'CalibreWeb, sans-serif',
  serif: 'ITC-Charter, serif',
};

export const lineHeights = {
  __new_theme__: [
    '1em', // 0
    '1.1em', // 1
    '1.2em', // 2
    '1.3em', // 3
    '1.4em', // 4
    '1.7em', // 5
  ],
};

export const letterSpacings = {
  __new_theme__: [
    '-0.015em', // 0
    '-0.01em', // 1
    '0em', // 2 – normal
    '0.015em', // 3
    '0.02em', // 4
    '0.025em', // 5
    '0.03em', // 6
    '0.035em', // 7
    '0.045em', // 8
    '0.055em', // 9
  ],
};

export const fontWeights = {
  __new_theme__: [
    300, // 0 – light
    400, // 1 – regular
  ],
};

export const textTransforms = {
  __new_theme__: ['none', 'uppercase'],
};

export const textStyles = {
  heading: {
    xxlarge: {
      fontSize: fontSizes.__new_theme__[8], // 72px
      lineHeight: lineHeights.__new_theme__[5], // 1.7em
      letterSpacing: letterSpacings.__new_theme__[0], // -0.015em
    },
    xlarge: {
      fontSize: fontSizes.__new_theme__[7], // 52px
      lineHeight: lineHeights.__new_theme__[1], // 1.1em
      letterSpacing: letterSpacings.__new_theme__[1], // -0.01em
    },
    large: {
      fontSize: fontSizes.__new_theme__[6], // 36px
      lineHeight: lineHeights.__new_theme__[2], // 1.1em
      letterSpacing: letterSpacings.__new_theme__[2], // 0em
    },
    regular: {
      fontSize: fontSizes.__new_theme__[5], // 24px
      lineHeight: lineHeights.__new_theme__[3], // 1.3em
      letterSpacing: letterSpacings.__new_theme__[3], // 0.015em
    },
    small: {
      fontSize: fontSizes.__new_theme__[2], // 16px
      lineHeight: lineHeights.__new_theme__[4], // 1.4em
      letterSpacing: letterSpacings.__new_theme__[6], // 0.03em
      fontWeight: fontWeights.__new_theme__[1], // regular
    },
  },
  subheading: {
    regular: {
      fontSize: fontSizes.__new_theme__[1], // 14px
      lineHeight: lineHeights.__new_theme__[5], // 1.7em
      letterSpacing: letterSpacings.__new_theme__[9], // 0.055em
      fontWeight: fontWeights.__new_theme__[1], // regular
      textTransform: textTransforms.__new_theme__[1], // uppercase
    },
  },
  body: {
    large: {
      fontSize: fontSizes.__new_theme__[3], // 18px
      lineHeight: lineHeights.__new_theme__[4], // 1.4em
      letterSpacing: letterSpacings.__new_theme__[5], // 0.025em
    },
    regular: {
      fontSize: fontSizes.__new_theme__[2], // 16px
      lineHeight: lineHeights.__new_theme__[5], // 1.7em
      letterSpacing: letterSpacings.__new_theme__[6], // 0.03em
      fontWeight: fontWeights.__new_theme__[0], // light (default?)
    },
    small: {
      fontSize: fontSizes.__new_theme__[1], // 16px
      lineHeight: lineHeights.__new_theme__[4], // 1.4em
      letterSpacing: letterSpacings.__new_theme__[7], // 0.035em
    },
    xsmall: {
      fontSize: fontSizes.__new_theme__[0], // 12px
      lineHeight: lineHeights.__new_theme__[3], // 1.3em
      letterSpacing: letterSpacings.__new_theme__[8], // 0.045em
    },
  },
  action: {
    large: {
      fontSize: fontSizes.__new_theme__[4], // 20px
      lineHeight: lineHeights.__new_theme__[0], // 1em
      letterSpacing: letterSpacings.__new_theme__[4], // 0.02em
      fontWeight: fontWeights.__new_theme__[1], // regular
    },
    regular: {
      fontSize: fontSizes.__new_theme__[2], // 16px
      lineHeight: lineHeights.__new_theme__[0], // 1em
      letterSpacing: letterSpacings.__new_theme__[6], // 0.03em
      fontWeight: fontWeights.__new_theme__[1], // regular
    },
    small: {
      fontSize: fontSizes.__new_theme__[1], // 14px
      lineHeight: lineHeights.__new_theme__[0], // 1em
      letterSpacing: letterSpacings.__new_theme__[7], // 0.035em
      fontWeight: fontWeights.__new_theme__[1], // regular
    },
  },
};

// colors
export const colors = {
  __new_theme__: {
    black: '#000',
    purple: '#6800c3',
    violet: '#3f0076',
    red: '#da1616',
    orange: '#ff9f00',
    green: '#28a354',
    grey: {
      100: '#f1f4f5',
      200: '#e9eef0',
      300: '#cdd3d6',
      400: '#b0b7b9',
      600: '#747d80',
      900: '#212121',
    },
    white: '#fff',
  },
};

colors.__new_theme__.modes = {
  light: {
    primary: colors.__new_theme__.purple,
    info: colors.__new_theme__.violet,
    success: colors.__new_theme__.green,
    warning: colors.__new_theme__.orange,
    danger: colors.__new_theme__.red,
  },
};

// spacing
export const baseSpacing = 4;
export const space = {
  __new_theme__: [
    baseSpacing * 0, // 0
    baseSpacing * 1, // 4
    baseSpacing * 2, // 8
    baseSpacing * 3, // 12
    baseSpacing * 4, // 16
    baseSpacing * 6, // 24
    baseSpacing * 8, // 32
    baseSpacing * 12, // 48
    baseSpacing * 16, // 64
    baseSpacing * 32, // 128
    baseSpacing * 64, // 256
  ],
};

// extra
export const shadows = {
  __new_theme__: {
    xlarge: '0 2px 16px rgba(0, 0, 0, 0.1);',
  },
};

export const zIndices = {
  modal: {
    panel: 250,
    overlay: 249,
  },
  dropdown: {
    panel: 300,
    overlay: 299,
  },
};
