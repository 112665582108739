import styled from 'styled-components';
import transition from 'src/mixins/css-transitions';
import { sizeVariants } from './variants';

const ButtonBase = styled.button.attrs({
  className: 'button-spacing',
})`
  align-items: center;
  border: none;
  border-radius: 2px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  font-family: ${props => props.theme.fontFamilies.sansSerif};
  ${sizeVariants};
  filter: opacity(${props => (props.disabled ? '45%' : '100%')});
  line-height: ${props => props.theme.lineHeights[1]}px;
  margin: 6px 0;
  position: relative;
  transition: ${transition({ property: 'background-color' })};

  .button-spacing + & {
    margin-left: 12px;
  }

  i {
    color: inherit;
    margin-right: 10px;
  }
`;

export default ButtonBase;
