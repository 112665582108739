import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { variants } from 'src/utils/theme-helper';
import {
  bodyTextStyle,
  bodySerifTextStyle,
  captionTextStyle,
  captionSmallTextStyle,
  headingTwoTextStyle,
} from 'src/mixins/text-styles';

const TEXT_STYLE_MAP = {
  body: css`
    ${bodyTextStyle};
  `,
  bodySerif: css`
    ${bodySerifTextStyle};
  `,
  caption: css`
    ${captionTextStyle};
  `,
  captionSmall: css`
    ${captionSmallTextStyle};
  `,
  heading: css`
    ${headingTwoTextStyle};
  `,
};
export const textStyleOptions = Object.keys(TEXT_STYLE_MAP);
export const textStyleProperty = variants('textStyle', TEXT_STYLE_MAP);
export const textStylePropType = PropTypes.oneOf(textStyleOptions);
