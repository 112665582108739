import React from 'react';
import PropTypes from 'prop-types';

import AspectRatioBox from 'src/components/AspectRatioBox';

import Img from '../styles/SquareImageImg';

const SquareImage = ({ id, className, alt, fit, src }) => (
  <AspectRatioBox id={id} className={className} ratio="square">
    <Img alt={alt} src={src} fit={fit} />
  </AspectRatioBox>
);

SquareImage.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
  fit: PropTypes.string,
};

SquareImage.defaultProps = {
  id: null,
  className: null,
  alt: 'Square image',
  fit: 'contain',
};

export default SquareImage;
