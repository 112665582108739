import styled from 'styled-components';

const SquareImageImg = styled.img`
  object-fit: ${props => props.fit};
`;

SquareImageImg.defaultProps = {
  fit: 'contain',
};

export default SquareImageImg;
