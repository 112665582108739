import styled from 'styled-components';
import { transparentize } from 'polished';

const DropdownMenuOverlay = styled.div`
  background-color: ${props => transparentize(0.75, props.theme.colors.black)};
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${props => props.theme.zIndexes.dropdown.overlay};

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    display: none;
  }
`;

export default DropdownMenuOverlay;
