import styled, { keyframes } from 'styled-components';
import Swipeable from 'react-swipeable';

import getPosition from 'src/utils/position-helper';

const Slide = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const DropdownMenuPanel = styled(Swipeable)`
  background-color: #fff;
  bottom: 0;
  box-shadow: ${props => props.theme.shadows.medium};
  display: flex;
  flex-direction: column;
  left: 0;
  padding: ${props => props.theme.space[3]}px 0;
  position: fixed;
  right: 0;
  z-index: ${props => props.theme.zIndexes.dropdown.panel};

  &.dropdown-menu-enter {
    animation: 200ms ${Slide} ease-out;
  }

  &.dropdown-menu-exit {
    animation: 200ms ${Slide} ease-in reverse forwards;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    bottom: unset;
    left: unset;
    right: unset;
    height: auto;
    position: absolute;
    width: ${props => props.width};
    ${getPosition};

    &.dropdown-menu-enter,
    &.dropdown-menu-exit {
      animation: none;
    }

    &.dropdown-menu-exit,
    &.dropdown-menu-exit-done {
      display: none;
    }
  }
`;

DropdownMenuPanel.defaultProps = {
  width: 'auto',
};

export default DropdownMenuPanel;
