import React from 'react';
import PropTypes from 'prop-types';

import HintText from './styles/HintText';
import { KIND_OPTIONS, SIZE_OPTIONS } from './constants';

const HintMessage = ({ children, ...props }) => (
  <HintText {...props}>{children}</HintText>
);

HintMessage.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  /**
   * KIND_OPTIONS:
   * default
   * success
   * warning
   * error
   */
  kind: PropTypes.oneOf(KIND_OPTIONS),
  /**
   * SIZE_OPTIONS:
   * tiny
   * small
   * medium
   * large
   * huge
   */
  size: PropTypes.oneOf(SIZE_OPTIONS),
  children: PropTypes.node.isRequired,
};

HintMessage.defaultProps = {
  id: null,
  className: null,
  kind: 'default',
  size: 'medium',
};

export default HintMessage;
