import { css } from 'styled-components';

export const TEXT_STYLES_MARGIN_MAP = {
  body: css`
    ${props =>
      props.noMargin ? 0 : `0 0 ${4 * props.theme.baselineGrid}px 0`};
  `,
  bodySerif: css`
    ${props =>
      props.noMargin ? 0 : `0 0 ${3 * props.theme.baselineGrid}px 0`};
  `,
  caption: css`
    ${props =>
      props.noMargin ? 0 : `0 0 ${2 * props.theme.baselineGrid}px 0`};
  `,
  captionSmall: css`
    ${props =>
      props.noMargin ? 0 : `0 0 ${2 * props.theme.baselineGrid}px 0`};
  `,
};
