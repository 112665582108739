import styled from 'styled-components';

import { sizeVariants } from './variants';

const horizontalPadding = props => props.theme.baselineGrid * 11;
const verticalPadding = props => props.theme.baselineGrid * 10;

const ModalDialog = styled.div`
  background-color: #fff;
  box-shadow: ${props => props.theme.shadows.large};
  display: flex;
  flex-direction: column;
  padding: ${verticalPadding}px ${horizontalPadding}px;
  z-index: ${props => props.theme.zIndexes.modal.panel};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    height: unset;
    position: relative;
    width: ${props => props.width || sizeVariants(props)};
  }
`;

export default ModalDialog;
