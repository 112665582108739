import React from 'react';
import PropTypes from 'prop-types';

import Container from '../styles/DetailLayoutContainer';
import Content from '../styles/DetailLayoutContent';
import Top from '../styles/DetailLayoutTop';

const DetailLayout = ({ id, className, children }) => (
  <Container id={id} className={className}>
    {children}
  </Container>
);

DetailLayout.Top = Top;
DetailLayout.Top.displayName = 'DetailLayout.Top';

DetailLayout.Content = Content;
DetailLayout.Content.displayName = 'DetailLayout.Content';

DetailLayout.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

DetailLayout.defaultProps = {
  id: null,
  className: null,
};

export default DetailLayout;
