import styled from 'styled-components';

const ModalContainer = styled.div`
  align-items: center;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${props => props.theme.zIndexes.modal.panel};
`;

export default ModalContainer;
