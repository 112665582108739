import React from 'react';
import PropTypes from 'prop-types';
import { colorPropType } from 'src/mixins/common-properties';
import HeadingThree from './styles/HeadingThree';

const H3 = ({ children, ...props }) => (
  <HeadingThree {...props}>{children}</HeadingThree>
);

H3.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  color: colorPropType,
  noMargin: PropTypes.bool,
};

H3.defaultProps = {
  id: null,
  className: null,
  children: null,
  color: 'black',
  noMargin: false,
};
export default H3;
