import { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

class Portal extends Component {
  constructor(props) {
    super(props);

    this.portalElement = window.document.createElement('div');
  }

  componentDidMount() {
    window.document.body.appendChild(this.portalElement);

    this.refreshStyles();
  }

  componentDidUpdate() {
    this.refreshStyles();
  }

  componentWillUnmount() {
    window.document.body.removeChild(this.portalElement);
  }

  refreshStyles() {
    const { style } = this.props;

    if (style) {
      this.portalElement.style.cssText = Object.entries(style).reduce(
        (s, [propName, propValue]) => `${s}${propName}:${propValue};`,
        '',
      );
    }
  }

  render() {
    const { children } = this.props;
    return createPortal(children, this.portalElement);
  }
}

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
};

Portal.defaultProps = {
  style: null,
};

export default Portal;
