import React from 'react';
import PropTypes from 'prop-types';

import Button from 'src/components/Button';
import FullPageContainer from 'src/styles/FullPageContainer';
import FullScreenContainer from 'src/styles/FullScreenContainer';
import GeneralLayout from 'src/layouts/GeneralLayout';
import PicterLogoSVG from 'src/assets/PicterLogoSVG';

import NoPermissionPageDefaultTitle from './components/NoPermissionPageDefaultTitle';
import NoPermissionPageDefaultText from './components/NoPermissionPageDefaultText';
import NoPermissionSVG from './assets/NoPermissionSVG';
import HelpcenterIcon from './styles/NoPermissionPageHelpcenterIcon';

const NoPermissionPage = ({ onClickAction, onClickHelp, title, text }) => (
  <FullScreenContainer>
    <GeneralLayout>
      <GeneralLayout.Top>
        <GeneralLayout.Top.AlignLeft>
          <PicterLogoSVG />
          <Button
            css="text-transform: unset;"
            onClick={event =>
              onClickAction
                ? onClickAction(event)
                : window.location.replace('/')
            }
            flat
          >
            Back to home
          </Button>
        </GeneralLayout.Top.AlignLeft>
      </GeneralLayout.Top>
      <GeneralLayout.Content>
        <FullPageContainer>
          <NoPermissionSVG />
          <br />
          <br />
          {title}
          <br />
          {text}
          <HelpcenterIcon onClick={onClickHelp} />
        </FullPageContainer>
      </GeneralLayout.Content>
    </GeneralLayout>
  </FullScreenContainer>
);

NoPermissionPage.propTypes = {
  onClickAction: PropTypes.func,
  onClickHelp: PropTypes.func,
  title: PropTypes.node,
  text: PropTypes.node,
};

NoPermissionPage.defaultProps = {
  onClickAction: null,
  onClickHelp: null,
  title: <NoPermissionPageDefaultTitle />,
  text: <NoPermissionPageDefaultText />,
};

export default NoPermissionPage;
