import React from 'react';
import PropTypes from 'prop-types';
import H2 from 'src/components/H2';
import P from 'src/components/P';
import Button from 'src/components/Button';
import FullPageContainer from 'src/styles/FullPageContainer';

import IllustrationSVG from './assets/IllustrationSVG';

const MaintenancePage = ({ onClickAction }) => (
  <FullPageContainer>
    <IllustrationSVG />
    <br />
    <br />
    <H2 color="muted" noMargin>
      Our systems are on a quick break
    </H2>
    <P color="muted">
      {
        "Don't worry, this is a planned maintenance. Check back in a few minutes."
      }
    </P>
    <br />
    <br />
    <Button
      onClick={event =>
        onClickAction ? onClickAction(event) : window.location.reload()
      }
      outlined
    >
      Retry
    </Button>
  </FullPageContainer>
);

MaintenancePage.propTypes = {
  onClickAction: PropTypes.func,
};

MaintenancePage.defaultProps = {
  onClickAction: null,
};

export default MaintenancePage;
