// https://github.com/styled-components/styled-theming
function getThemeValue(name, props, values) {
  const value = props.theme && props.theme[name];

  let themeValue;

  if (typeof value === 'function') {
    themeValue = value(values);
  } else {
    themeValue = values[value];
  }

  if (typeof themeValue === 'function') {
    return themeValue(props);
  }
  return themeValue;
}

export function theming(name, values) {
  return props => getThemeValue(name, props, values);
}

theming.variants = (name, prop, values) => props => {
  const variant = props[prop] && values[props[prop]];
  return variant && getThemeValue(name, props, variant);
};

function getVariantValue(name, props, values) {
  const value = props && props[name];

  let variantValue;

  if (typeof value === 'function') {
    variantValue = value(values);
  } else {
    variantValue = values[value];
  }

  if (typeof variantValue === 'function') {
    return variantValue(props);
  }
  return variantValue;
}

export const variants = (name, values) => props =>
  getVariantValue(name, props, values);
