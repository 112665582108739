import React from 'react';
import PropTypes from 'prop-types';

import Button from 'src/components/Button';
import H2 from 'src/components/H2';
import P from 'src/components/P';
import FullPageContainer from 'src/styles/FullPageContainer';

import IceCreamSVG from './assets/IceCreamSVG';

const ErrorPage = ({ onClickAction }) => (
  <FullPageContainer>
    <IceCreamSVG />
    <br />
    <br />
    <H2 color="muted" noMargin>
      Oops!
    </H2>
    <P color="muted">Something went wrong!</P>
    <br />
    <br />
    <Button
      onClick={event =>
        onClickAction ? onClickAction(event) : window.location.reload()
      }
      outlined
    >
      Reload content
    </Button>
  </FullPageContainer>
);

ErrorPage.propTypes = {
  onClickAction: PropTypes.func,
};

ErrorPage.defaultProps = {
  onClickAction: null,
};

export default ErrorPage;
