import styled from 'styled-components';

import { captionSmallTextStyle } from 'src/mixins/text-styles';
import transition from 'src/mixins/css-transitions';

import TextAreaInput from './TextAreaInput';
import TextFieldInput from './TextFieldInput';

export const TextFieldLabel = styled.label`
  ${captionSmallTextStyle};
  color: ${props => props.theme.colors.gray['500']};
  opacity: 1;
  transition: ${transition({ property: 'opacity' })};

  ${TextFieldInput /* sc-sel */}.empty ~ &,
    ${TextAreaInput /* sc-sel */}.empty ~ & {
    opacity: ${props => (props.floated ? 1 : 0)};
  }

  ${TextFieldInput /* sc-sel */}:focus ~ &,
    ${TextAreaInput /* sc-sel */}:focus ~ & {
    color: ${props => props.theme.colors.primary.default};
  }

  ${TextFieldInput /* sc-sel */}.error ~ &,
    ${TextAreaInput /* sc-sel */}.error ~ & {
    color: ${props => props.theme.colors.error.default};
  }

  ${TextFieldInput /* sc-sel */}:disabled ~ &,
    ${TextAreaInput /* sc-sel */}:disabled ~ & {
    color: ${props => props.theme.colors.gray['600']};
  }

  ${TextFieldInput /* sc-sel */}.empty:required ~ &,
    ${TextAreaInput /* sc-sel */}.empty:required ~ & {
    color: ${props => props.theme.colors.warning.default};
  }
`;

export default TextFieldLabel;
