import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import isFunction from 'lodash.isfunction';

import * as CustomPropTypes from 'src/utils/custom-prop-types';
import MediaQuery from 'src/components/MediaQuery';
import KeyboardistNoSSR from 'src/components/KeyboardistNoSSR';
import Portal from 'src/__next__/components/Portal';

import DropdownContent from './DropdownContent';
import DropdownHeader from './DropdownHeader';
import DropdownGroup from './DropdownGroup';
import DropdownItem from './DropdownItem';
import DropdownMenuOverlay from '../styles/DropdownMenuOverlay';
import DropdownMenuPanel from '../styles/DropdownMenuPanel';
import DropdownSeparator from './DropdownSeparator';

import DropdownContext from './DropdownContext';

class DropdownMenu extends Component {
  static contextType = DropdownContext;

  handleClickOutside = evt => {
    const { closeMenu } = this.context;
    closeMenu();
    evt.stopPropagation();
  };

  position() {
    const { horizontalOffset, verticalOffset } = this.props;
    const { posX, posY } = this.context;
    const yProperty = posY === 'top' ? 'bottom' : 'top';
    const xProperty = posX === 'left' ? 'right' : 'left';

    return {
      [yProperty]: `calc(100% + ${verticalOffset})`,
      [xProperty]: `calc(0px + ${horizontalOffset})`,
    };
  }

  render() {
    const { closeMenu } = this.context;
    const { width } = this.props;
    let { children } = this.props;

    if (isFunction(children)) {
      children = children(this.context);
    }

    return (
      <MediaQuery query={{ minWidth: 0 }}>
        {matches =>
          matches ? (
            <DropdownMenuPanel width={width} {...this.position()}>
              <KeyboardistNoSSR bindings={{ Escape: closeMenu }} />
              {children}
            </DropdownMenuPanel>
          ) : (
            <Portal>
              <DropdownMenuPanel
                onSwipedDown={closeMenu}
                width={width}
                {...this.position()}
              >
                {children}
              </DropdownMenuPanel>
              <DropdownMenuOverlay onClick={closeMenu} role="button" />
            </Portal>
          )
        }
      </MediaQuery>
    );
  }
}

DropdownMenu.propTypes = {
  children: CustomPropTypes.oneOrMoreOfType([PropTypes.element, PropTypes.node])
    .isRequired,
  horizontalOffset: PropTypes.string,
  verticalOffset: PropTypes.string,
  width: PropTypes.string,
};

DropdownMenu.defaultProps = {
  horizontalOffset: '0px',
  verticalOffset: '0px',
  width: 'auto',
};

const DropdownMenuWithClickOutside = onClickOutside(DropdownMenu);

DropdownMenuWithClickOutside.displayName = 'DropdownMenu';
DropdownMenuWithClickOutside.defaultProps = {
  eventTypes: ['click', 'touchstart'],
};

DropdownMenuWithClickOutside.Content = DropdownContent;
DropdownMenuWithClickOutside.Group = DropdownGroup;
DropdownMenuWithClickOutside.Header = DropdownHeader;
DropdownMenuWithClickOutside.Item = DropdownItem;
DropdownMenuWithClickOutside.Separator = DropdownSeparator;

export default DropdownMenuWithClickOutside;
