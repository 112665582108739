import styled, { css } from 'styled-components';

const DropdownHeaderContainer = styled.div`
  align-items: center;
  background-color: transparent;
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.space[3]}px;
  padding: 0 ${props => props.theme.space[4]}px;
  text-align: center;
  ${({ disabled }) =>
    disabled &&
    css`
      h4 {
        cursor: not-allowed;
        color: ${props => props.theme.colors.gray[300]};
      }
    `}
`;

export default DropdownHeaderContainer;
