import React from 'react';
import PropTypes from 'prop-types';
import * as CustomPropTypes from 'src/utils/custom-prop-types';

import PanelContainer from './styles/PanelContainer';
import InteractivePanelContainer from './styles/InteractivePanelContainer';

import { ELEVATION_OPTIONS, POSITION_OPTIONS, SIZE_OPTIONS } from './constants';

const Panel = ({ children, interactive, onClick, ...props }) =>
  interactive || onClick ? (
    <InteractivePanelContainer {...props} onClick={onClick}>
      {children}
    </InteractivePanelContainer>
  ) : (
    <PanelContainer {...props}>{children}</PanelContainer>
  );

Panel.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  elevation: PropTypes.oneOf(ELEVATION_OPTIONS),
  interactive: PropTypes.bool,
  onClick: PropTypes.func,
  position: PropTypes.oneOf(POSITION_OPTIONS),
  top: CustomPropTypes.numberOrString,
  right: CustomPropTypes.numberOrString,
  bottom: CustomPropTypes.numberOrString,
  left: CustomPropTypes.numberOrString,
  size: PropTypes.oneOf(SIZE_OPTIONS),
  width: PropTypes.string,
};

Panel.defaultProps = {
  id: null,
  className: null,
  elevation: 'medium',
  interactive: null,
  onClick: null,
  position: 'static',
  top: null,
  right: null,
  bottom: null,
  left: null,
  size: 'medium',
  width: null,
};

Panel.displayName = 'Panel';

export default Panel;
