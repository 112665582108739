import styled from 'styled-components';
import tag from 'clean-tag';

const CardGridContainer = styled(tag)`
  display: grid;
  grid-gap: ${props => props.theme.space[1]}px;
  grid-template-columns: repeat(auto-fill, minmax(93px, 1fr));

  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
`;

export default CardGridContainer;
