import { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Based on https://github.com/kadirahq/react-no-ssr/blob/master/src/index.js
 * Because the project hasn't been updated in a while and its peer dependencies
 * are outdated also React changed its rendering process a bit.
 */
class NoSSR extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      canRender: false,
    };
  }

  componentDidMount() {
    this.setState({ canRender: true }); // eslint-disable-line
  }

  render() {
    const { render, onSSR } = this.props;
    const { canRender } = this.state;
    return canRender ? render() : onSSR;
  }
}

NoSSR.propTypes = {
  render: PropTypes.func.isRequired,
  onSSR: PropTypes.node,
};

NoSSR.defaultProps = {
  onSSR: null,
};

export default NoSSR;
