import styled from 'styled-components';
import { position, bottom, left, right, top } from 'styled-system';
import { colorProperty } from 'src/mixins/common-properties';

const ProgressBarContainer = styled.div`
  background-color: ${colorProperty};
  border-radius: ${props => props.size}px;
  display: flex;
  height: ${props => props.size}px;
  overflow: hidden;
  width: 100%;
  ${position};
  ${bottom};
  ${left};
  ${right};
  ${top};
`;

ProgressBarContainer.defaultProps = {
  color: 'transparent',
  size: 2,
};

export default ProgressBarContainer;
