import React from 'react';
import P from 'src/components/P';

const NoPermissionPageDefaultTitle = () => (
  <P color="#747d80">
    Want in? Ask the owner for access, or switch to an account with permission.
  </P>
);

export default NoPermissionPageDefaultTitle;
