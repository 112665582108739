import React from 'react';
import H2 from 'src/components/H2';

const NoPermissionPageDefaultTitle = () => (
  <H2 color="black" noMargin>
    You need permission to see this project
  </H2>
);

export default NoPermissionPageDefaultTitle;
