import { css } from 'styled-components';

export const SIZES_MAP = {
  small: css`
    font-size: ${props => 4 * props.theme.baselineGrid}px;
    font-weight: normal;
    letter-spacing: 1px;
    text-transform: capitalize;
  `,
  medium: css`
    font-size: ${props => 3.5 * props.theme.baselineGrid}px;
    font-weight: 500;
    letter-spacing: 1.3px;
    text-transform: uppercase;
  `,
  large: css`
    font-size: ${props => 4.5 * props.theme.baselineGrid}px;
    font-weight: normal;
    letter-spacing: 1.3px;
    text-transform: uppercase;
  `,
};

export const SIZE_OPTIONS = Object.keys(SIZES_MAP);

export const COLOR_SCHEMES_MAP = {
  blacknwhite: props =>
    props.light ? props.theme.colors.white : props.theme.colors.black,
  destructive: props => props.theme.colors.error.default,
  grayscale: props => props.theme.colors.gray['600'],
  primary: props =>
    props.light ? props.theme.colors.white : props.theme.colors.primary.default,
};

export const COLOR_SCHEME_OPTIONS = Object.keys(COLOR_SCHEMES_MAP);

export const NORMAL_COLOR_SCHEMES_MAP = {
  blacknwhite: props =>
    props.light ? props.theme.colors.white : props.theme.colors.black,
  destructive: props => props.theme.colors.error.default,
  grayscale: props => props.theme.colors.gray['600'],
  primary: props =>
    props.light
      ? props.theme.colors.primary.fallback
      : props.theme.colors.primary.default,
};
