import { createContext } from 'react';

const DropdownContext = createContext({
  closeMenu: f => f,
  goBack: f => f,
  goTo: f => f,
  posX: null,
  posY: null,
});

export default DropdownContext;
