import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as CustomPropTypes from 'src/utils/custom-prop-types';
import { deprecateProperty } from 'src/utils/deprecation-log';
import Icon from 'src/components/Icon';
import TruncateText from 'src/styles/TruncateText';

import DropdownItemButton from '../styles/DropdownItemButton';
import DropdownItemLink from '../styles/DropdownItemLink';

const DropdownItem = ({
  active,
  disabled,
  footer,
  hint,
  large,
  icon,
  label,
  link,
  size,
  ...props
}) => {
  if (large) {
    deprecateProperty('Dropdown', 'large', 'Use `size="large"` instead.');
  }

  const DropdownItemComponent = link ? DropdownItemLink : DropdownItemButton;
  let itemIcon;

  switch (typeof icon) {
    case 'string':
      itemIcon = <Icon type={icon} color="inherit" />;
      break;
    case 'number':
      itemIcon = <Icon type={icon} color="inherit" size="small" />;
      break;
    default:
      itemIcon = icon;
      break;
  }

  return (
    <DropdownItemComponent
      {...props}
      className={classNames({ active, disabled })}
      active={active}
      disabled={disabled}
      footer={footer}
      size={large ? 'large' : size}
    >
      {itemIcon}
      <div>
        <TruncateText>{label}</TruncateText>
        {hint && <TruncateText is="small">{hint}</TruncateText>}
      </div>
    </DropdownItemComponent>
  );
};

DropdownItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  footer: PropTypes.bool,
  hint: PropTypes.node,
  icon: PropTypes.oneOfType([
    CustomPropTypes.elementOf(Icon),
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  large: PropTypes.bool,
  link: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'large', 'auto']),
};

DropdownItem.defaultProps = {
  active: false,
  disabled: false,
  footer: false,
  hint: null,
  icon: null,
  large: false,
  link: false,
  size: 'normal',
};

export default DropdownItem;
