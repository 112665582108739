import React, { Component } from 'react';

class KeyboardistNoSSR extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      Keyboardist: null,
    };
  }

  componentDidMount() {
    /* eslint-disable */
    const Keyboardist = require('react-keyboardist').default;
    this.setState({ Keyboardist });
    /* eslint-enable */
  }

  render() {
    const { Keyboardist } = this.state;
    return Keyboardist ? <Keyboardist {...this.props} /> : null;
  }
}

export default KeyboardistNoSSR;
