import React from 'react';

const FourOhFourTreySVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="228"
    height="245"
    viewBox="0 0 228 245"
  >
    <defs>
      <rect id="a" width="118" height="166" rx="8" />
      <path
        id="b"
        d="M3.75 13.86a716.408 716.408 0 0 1 57.398-2.305c17.898 0 35.795.673 53.693 2.018a5 5 0 0 1 4.625 4.986v.732a4.955 4.955 0 0 1-5.321 4.941 716.095 716.095 0 0 0-52.997-1.965c-19.079 0-38.158.764-57.237 2.292a1 1 0 0 1-1.08-.996v-8.705a1 1 0 0 1 .92-.997z"
      />
      <path id="c" d="M1.53 0h73.533l-7.154 80H7.189z" />
      <path
        id="d"
        d="M7.742 19.346a716.396 716.396 0 0 0 57.237 2.293c17.666 0 35.331-.655 52.997-1.965a4.955 4.955 0 0 1 5.32 4.94v.733a5 5 0 0 1-4.624 4.986 716.156 716.156 0 0 1-53.693 2.018c-19.132 0-38.265-.769-57.397-2.306a1 1 0 0 1-.92-.997v-8.705a1 1 0 0 1 1.08-.997z"
      />
      <rect
        id="e"
        width="16.662"
        height="8.331"
        x="-3.427"
        y="15.861"
        rx="4.166"
      />
      <rect
        id="f"
        width="12.393"
        height="8.331"
        x="-1.367"
        y="17.994"
        rx="4.166"
      />
      <rect id="g" width="118" height="166" rx="8" />
      <path
        id="h"
        d="M3.75 13.86a716.408 716.408 0 0 1 57.398-2.305c17.898 0 35.795.673 53.693 2.018a5 5 0 0 1 4.625 4.986v.732a4.955 4.955 0 0 1-5.321 4.941 716.095 716.095 0 0 0-52.997-1.965c-19.079 0-38.158.764-57.237 2.292a1 1 0 0 1-1.08-.996v-8.705a1 1 0 0 1 .92-.997z"
      />
      <path id="i" d="M1.53 0h73.533l-7.154 80H7.189z" />
      <path
        id="j"
        d="M7.742 19.346a716.396 716.396 0 0 0 57.237 2.293c17.666 0 35.331-.655 52.997-1.965a4.955 4.955 0 0 1 5.32 4.94v.733a5 5 0 0 1-4.624 4.986 716.156 716.156 0 0 1-53.693 2.018c-19.132 0-38.265-.769-57.397-2.306a1 1 0 0 1-.92-.997v-8.705a1 1 0 0 1 1.08-.997z"
      />
      <rect
        id="k"
        width="16.662"
        height="8.331"
        x="-3.427"
        y="15.861"
        rx="4.166"
      />
      <rect
        id="l"
        width="12.393"
        height="8.331"
        x="-1.367"
        y="17.994"
        rx="4.166"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(-13)">
      <rect width="114" height="165" x="82" y="44" fill="#EBEBEB" rx="8" />
      <g transform="translate(72 38)">
        <use fill="#F5F5F5" />
        <rect
          width="116.5"
          height="164.5"
          x=".75"
          y=".75"
          stroke="#BDBDBD"
          strokeWidth="1.5"
          rx="8"
        />
      </g>
      <path
        stroke="#BDBDBD"
        strokeWidth="1.5"
        d="M82.2 41.75a6.45 6.45 0 0 0-6.45 6.45v145.6a6.45 6.45 0 0 0 6.45 6.45h97.6a6.45 6.45 0 0 0 6.45-6.45V48.2a6.45 6.45 0 0 0-6.45-6.45H82.2z"
      />
      <g stroke="#BDBDBD" strokeLinecap="round" strokeWidth="1.5">
        <path d="M92 183c-6.075 0-11 4.925-11 11M92 59c-6.075 0-11-4.925-11-11M170 183c6.075 0 11 4.925 11 11M170 59c6.075 0 11-4.925 11-11" />
      </g>
      <path
        stroke="#BDBDBD"
        strokeWidth="1.5"
        d="M102 59.75A7.25 7.25 0 0 0 94.75 67v108a7.25 7.25 0 0 0 7.25 7.25h58a7.25 7.25 0 0 0 7.25-7.25V67a7.25 7.25 0 0 0-7.25-7.25h-58z"
      />
      <path
        fill="#EBEBEB"
        d="M84 44h93v1c0 6.627-5.373 12-12 12H96c-6.627 0-12-5.373-12-12v-1zM92 180h-2c-6.627 0-12 5.373-12 12V50c0 6.627 5.373 12 12 12h2v118z"
      />
      <rect
        width="90"
        height="138"
        x="86"
        y="52"
        fill="#D8D8D8"
        fillOpacity=".3"
        rx="12"
      />
      <g transform="translate(33)">
        <g
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path d="M17 119v-4M17 129v-4M10 122h4M20 122h4" />
        </g>
        <g
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path d="M157 17v-4M157 27v-4M150 20h4M160 20h4" />
        </g>
        <g
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path d="M171 218v-4M171 228v-4M164 221h4M174 221h4" />
        </g>
        <g
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path d="M60 225v-4M60 235v-4M53 228h4M63 228h4" />
        </g>
        <g
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path d="M7 24v-4M7 34v-4M0 27h4M10 27h4" />
        </g>
        <g
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path d="M185 96v-4M185 106v-4M178 99h4M188 99h4" />
        </g>
        <circle cx="21.5" cy="61.5" r="1.5" fill="#BDBDBD" />
        <circle cx="30.5" cy="92.5" r="1.5" fill="#BDBDBD" />
        <circle cx="64.5" cy="14.5" r="1.5" fill="#BDBDBD" />
        <circle cx="9.5" cy="4.5" r="1.5" fill="#BDBDBD" />
        <circle cx="176.5" cy="58.5" r="1.5" fill="#BDBDBD" />
        <circle cx="178.5" cy="1.5" r="1.5" fill="#BDBDBD" />
        <circle cx="185.5" cy="162.5" r="1.5" fill="#BDBDBD" />
        <circle cx="185.5" cy="162.5" r="1.5" fill="#BDBDBD" />
        <circle cx="171.5" cy="130.5" r="1.5" fill="#BDBDBD" />
        <circle cx="206.5" cy="81.5" r="1.5" fill="#BDBDBD" />
        <circle cx="126.5" cy="226.5" r="1.5" fill="#BDBDBD" />
        <circle cx="45.5" cy="214.5" r="1.5" fill="#BDBDBD" />
        <circle cx="12.5" cy="162.5" r="1.5" fill="#BDBDBD" />
        <circle cx="33.5" cy="134.5" r="1.5" fill="#BDBDBD" />
        <circle cx="169.5" cy="190.5" r="1.5" fill="#BDBDBD" />
        <circle cx="178.5" cy="236.5" r="1.5" fill="#BDBDBD" />
        <circle cx="32.5" cy="243.5" r="1.5" fill="#BDBDBD" />
        <circle cx="21.5" cy="197.5" r="1.5" fill="#BDBDBD" />
        <circle cx="64.5" cy="14.5" r="1.5" fill="#BDBDBD" />
      </g>
      <g>
        <g transform="rotate(10 -292.468 124.125)">
          <use fill="#EBEBEB" xlinkHref="#b" />
          <path
            stroke="#BDBDBD"
            strokeWidth="1.5"
            d="M3.81 14.609a.25.25 0 0 0-.23.249v8.705l.002.02a.25.25 0 0 0 .269.229 717.146 717.146 0 0 1 57.297-2.295c17.684 0 35.368.655 53.052 1.967a4.205 4.205 0 0 0 4.516-4.193v-.732a4.25 4.25 0 0 0-3.932-4.238 715.405 715.405 0 0 0-53.636-2.016c-19.112 0-38.225.768-57.337 2.304z"
          />
        </g>
        <g transform="rotate(8 -457.605 749.752)">
          <use fill="#F5F5F5" xlinkHref="#c" />
          <path
            stroke="#BDBDBD"
            strokeWidth="1.5"
            d="M2.336.75l5.552 78.5h59.335l7.02-78.5H2.336z"
          />
        </g>
        <path
          fill="#7300D7"
          d="M121.64 110.014l-4.416 5.366 1.124.305.388-1.386 3.166.858-.399 1.384 1.43.388-.782 2.698-1.421-.387-.523 1.834-3.12-.85.509-1.837-5.032-1.367.668-2.517 4.848-5.447 3.56.958zm7.416 13.665c-3.006-.822-4.44-3.642-3.48-6.873.967-3.23 3.798-4.894 6.885-4.06 3.082.832 4.49 3.649 3.44 6.867-1.042 3.22-3.846 4.887-6.845 4.066zm2.494-8.038c-1.164-.315-1.966.851-2.364 2.145-.398 1.294-.386 2.681.763 2.995 1.129.307 1.93-.859 2.338-2.152.409-1.292.406-2.678-.737-2.988zm15.116 1.137l-4.746 5.32 1.106.303.473-1.374 3.117.85-.484 1.372 1.408.385-.948 2.675-1.398-.384-.635 1.82-3.07-.845.62-1.82-4.953-1.357.822-2.494 5.183-5.401 3.505.95z"
        />
        <path
          fill="#D8D8D8"
          fillOpacity=".3"
          d="M99.117 104.629c4.283 23.078 14.186 31.166 27.915 24.467 14.197-6.698 27.37-10.815 38.96-12.534l-9.973 43.042-60.15-8.44 3.248-46.535z"
        />
        <g transform="rotate(13 -208.146 108.69)">
          <use fill="#F5F5F5" xlinkHref="#d" />
          <path
            stroke="#BDBDBD"
            strokeWidth="1.5"
            d="M7.682 20.094h-.02a.25.25 0 0 0-.25.25v8.704c0 .13.1.239.23.25A715.658 715.658 0 0 0 64.979 31.6c17.879 0 35.758-.672 53.636-2.016a4.25 4.25 0 0 0 3.932-4.238v-.732a4.205 4.205 0 0 0-4.516-4.193 716.86 716.86 0 0 1-53.052 1.967c-19.099 0-38.198-.765-57.297-2.295z"
          />
        </g>
        <g transform="rotate(102 -9.865 52.126)">
          <use fill="#F5F5F5" xlinkHref="#e" />
          <rect
            width="15.162"
            height="6.831"
            x="-2.677"
            y="16.611"
            stroke="#BDBDBD"
            strokeWidth="1.5"
            rx="3.416"
          />
        </g>
        <g transform="rotate(102 -10.105 54.087)">
          <use fill="#EBEBEB" xlinkHref="#f" />
          <rect
            width="10.893"
            height="6.831"
            x="-.617"
            y="18.744"
            stroke="#BDBDBD"
            strokeWidth="1.5"
            rx="3.416"
          />
        </g>
      </g>
      <g>
        <g transform="translate(72 38)">
          <use fill="#F5F5F5" xlinkHref="#g" />
          <rect
            width="116.5"
            height="164.5"
            x=".75"
            y=".75"
            stroke="#BDBDBD"
            strokeWidth="1.5"
            rx="8"
          />
        </g>
        <path
          stroke="#BDBDBD"
          strokeWidth="1.5"
          d="M82.2 41.75a6.45 6.45 0 0 0-6.45 6.45v145.6a6.45 6.45 0 0 0 6.45 6.45h97.6a6.45 6.45 0 0 0 6.45-6.45V48.2a6.45 6.45 0 0 0-6.45-6.45H82.2z"
        />
        <g stroke="#BDBDBD" strokeLinecap="round" strokeWidth="1.5">
          <path d="M92 183c-6.075 0-11 4.925-11 11M92 59c-6.075 0-11-4.925-11-11M170 183c6.075 0 11 4.925 11 11M170 59c6.075 0 11-4.925 11-11" />
        </g>
        <path
          stroke="#BDBDBD"
          strokeWidth="1.5"
          d="M102 59.75A7.25 7.25 0 0 0 94.75 67v108a7.25 7.25 0 0 0 7.25 7.25h58a7.25 7.25 0 0 0 7.25-7.25V67a7.25 7.25 0 0 0-7.25-7.25h-58z"
        />
        <path
          fill="#EBEBEB"
          d="M84 44h93v1c0 6.627-5.373 12-12 12H96c-6.627 0-12-5.373-12-12v-1zM92 180h-2c-6.627 0-12 5.373-12 12V50c0 6.627 5.373 12 12 12h2v118z"
        />
      </g>
      <rect
        width="90"
        height="138"
        x="86"
        y="52"
        fill="#D8D8D8"
        fillOpacity=".3"
        rx="12"
      />
      <g transform="translate(33)">
        <g
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path d="M17 119v-4M17 129v-4M10 122h4M20 122h4" />
        </g>
        <g
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path d="M157 17v-4M157 27v-4M150 20h4M160 20h4" />
        </g>
        <g
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path d="M171 218v-4M171 228v-4M164 221h4M174 221h4" />
        </g>
        <g
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path d="M60 225v-4M60 235v-4M53 228h4M63 228h4" />
        </g>
        <g
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path d="M7 24v-4M7 34v-4M0 27h4M10 27h4" />
        </g>
        <g
          stroke="#BDBDBD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path d="M185 96v-4M185 106v-4M178 99h4M188 99h4" />
        </g>
        <circle cx="21.5" cy="61.5" r="1.5" fill="#BDBDBD" />
        <circle cx="30.5" cy="92.5" r="1.5" fill="#BDBDBD" />
        <circle cx="64.5" cy="14.5" r="1.5" fill="#BDBDBD" />
        <circle cx="9.5" cy="4.5" r="1.5" fill="#BDBDBD" />
        <circle cx="176.5" cy="58.5" r="1.5" fill="#BDBDBD" />
        <circle cx="178.5" cy="1.5" r="1.5" fill="#BDBDBD" />
        <circle cx="185.5" cy="162.5" r="1.5" fill="#BDBDBD" />
        <circle cx="185.5" cy="162.5" r="1.5" fill="#BDBDBD" />
        <circle cx="171.5" cy="130.5" r="1.5" fill="#BDBDBD" />
        <circle cx="206.5" cy="81.5" r="1.5" fill="#BDBDBD" />
        <circle cx="126.5" cy="226.5" r="1.5" fill="#BDBDBD" />
        <circle cx="45.5" cy="214.5" r="1.5" fill="#BDBDBD" />
        <circle cx="12.5" cy="162.5" r="1.5" fill="#BDBDBD" />
        <circle cx="33.5" cy="134.5" r="1.5" fill="#BDBDBD" />
        <circle cx="169.5" cy="190.5" r="1.5" fill="#BDBDBD" />
        <circle cx="178.5" cy="236.5" r="1.5" fill="#BDBDBD" />
        <circle cx="32.5" cy="243.5" r="1.5" fill="#BDBDBD" />
        <circle cx="21.5" cy="197.5" r="1.5" fill="#BDBDBD" />
        <circle cx="64.5" cy="14.5" r="1.5" fill="#BDBDBD" />
      </g>
      <g>
        <g transform="rotate(10 -288.607 124.009)">
          <use fill="#EBEBEB" />
          <path
            stroke="#BDBDBD"
            strokeWidth="1.5"
            d="M3.81 14.609a.25.25 0 0 0-.23.249v8.705l.002.02a.25.25 0 0 0 .269.229 717.146 717.146 0 0 1 57.297-2.295c17.684 0 35.368.655 53.052 1.967a4.205 4.205 0 0 0 4.516-4.193v-.732a4.25 4.25 0 0 0-3.932-4.238 715.405 715.405 0 0 0-53.636-2.016c-19.112 0-38.225.768-57.337 2.304z"
          />
        </g>
        <g transform="rotate(8 -452.779 749.69)">
          <use fill="#F5F5F5" />
          <path
            stroke="#BDBDBD"
            strokeWidth="1.5"
            d="M2.336.75l5.552 78.5h59.335l7.02-78.5H2.336z"
          />
        </g>
        <path
          fill="#7300D7"
          d="M121.678 109.341l-4.416 5.367 1.125.304.388-1.385 3.166.857-.4 1.384 1.431.388-.783 2.698-1.42-.386-.523 1.834-3.12-.85.508-1.837-5.031-1.368.668-2.516 4.848-5.448 3.56.958zm7.416 13.666c-3.005-.822-4.44-3.642-3.48-6.873.967-3.23 3.798-4.894 6.886-4.06 3.082.832 4.49 3.648 3.44 6.867-1.042 3.22-3.846 4.887-6.846 4.066zm2.494-8.038c-1.163-.315-1.965.851-2.364 2.145-.397 1.294-.386 2.681.763 2.994 1.13.308 1.931-.858 2.339-2.15.408-1.293.406-2.679-.738-2.989zm15.116 1.137l-4.746 5.32 1.107.302.473-1.373 3.116.85-.484 1.372 1.408.385-.948 2.675-1.398-.384-.634 1.819-3.07-.844.62-1.82-4.953-1.357.821-2.495 5.183-5.4 3.505.95z"
        />
        <path
          fill="#D8D8D8"
          fillOpacity=".3"
          d="M99.156 103.957c4.282 23.078 14.185 31.165 27.914 24.467 14.198-6.699 27.37-10.816 38.961-12.534l-9.973 43.042-60.151-8.44 3.249-46.535z"
        />
        <g transform="rotate(13 -205.177 108.522)">
          <use fill="#F5F5F5" />
          <path
            stroke="#BDBDBD"
            strokeWidth="1.5"
            d="M7.682 20.094h-.02a.25.25 0 0 0-.25.25v8.704c0 .13.1.239.23.25A715.658 715.658 0 0 0 64.979 31.6c17.879 0 35.758-.672 53.636-2.016a4.25 4.25 0 0 0 3.932-4.238v-.732a4.205 4.205 0 0 0-4.516-4.193 716.86 716.86 0 0 1-53.052 1.967c-19.099 0-38.198-.765-57.297-2.295z"
          />
        </g>
        <g transform="rotate(102 -9.573 51.806)">
          <use fill="#F5F5F5" />
          <rect
            width="15.162"
            height="6.831"
            x="-2.677"
            y="16.611"
            stroke="#BDBDBD"
            strokeWidth="1.5"
            rx="3.416"
          />
        </g>
        <g transform="rotate(102 -9.814 53.766)">
          <use fill="#EBEBEB" />
          <rect
            width="10.893"
            height="6.831"
            x="-.617"
            y="18.744"
            stroke="#BDBDBD"
            strokeWidth="1.5"
            rx="3.416"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default FourOhFourTreySVG;
