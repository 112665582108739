import React from 'react';

const QuestionSVG = () => (
  <svg
    width="10"
    height="19"
    viewBox="0 0 10 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.57634 13.392H3.89634V11.952C3.89634 11.504 3.94434 11.104 4.04034 10.752C4.15234 10.384 4.29634 10.056 4.47234 9.76797C4.66434 9.47997 4.87234 9.22397 5.09634 8.99997C5.33634 8.75997 5.57634 8.52797 5.81634 8.30397L6.48834 7.70397C6.88834 7.38397 7.21634 7.01597 7.47234 6.59997C7.74434 6.18397 7.88034 5.66397 7.88034 5.03997C7.88034 4.59197 7.80034 4.19197 7.64034 3.83997C7.48034 3.48797 7.26434 3.19197 6.99234 2.95197C6.72034 2.71197 6.40034 2.52797 6.03234 2.39997C5.68034 2.27197 5.29634 2.20797 4.88034 2.20797C4.01634 2.20797 3.31234 2.47197 2.76834 2.99997C2.24034 3.52797 1.91234 4.19997 1.78434 5.01597L0.152344 4.72797C0.392344 3.47997 0.936344 2.50397 1.78434 1.79997C2.64834 1.07997 3.70434 0.719971 4.95234 0.719971C5.59234 0.719971 6.19234 0.815971 6.75234 1.00797C7.32834 1.18397 7.82435 1.45597 8.24035 1.82397C8.65635 2.17597 8.98434 2.61597 9.22434 3.14397C9.48034 3.67197 9.60835 4.27197 9.60835 4.94397C9.60835 5.45597 9.55234 5.90397 9.44034 6.28797C9.32834 6.67197 9.16834 7.01597 8.96034 7.31997C8.76834 7.62397 8.53634 7.90397 8.26434 8.15997C7.99234 8.39997 7.70434 8.64797 7.40034 8.90397C7.06434 9.17597 6.77634 9.42397 6.53634 9.64797C6.31234 9.87197 6.12834 10.112 5.98434 10.368C5.84034 10.608 5.73634 10.88 5.67234 11.184C5.60834 11.488 5.57634 11.856 5.57634 12.288V13.392ZM6.20034 16.848C6.20034 17.184 6.08034 17.488 5.84034 17.76C5.60034 18.016 5.28834 18.144 4.90434 18.144C4.52034 18.144 4.20834 18.016 3.96834 17.76C3.72834 17.488 3.60834 17.184 3.60834 16.848C3.60834 16.512 3.72834 16.216 3.96834 15.96C4.20834 15.688 4.52034 15.552 4.90434 15.552C5.28834 15.552 5.60034 15.688 5.84034 15.96C6.08034 16.216 6.20034 16.512 6.20034 16.848Z"
      fill="#747D80"
    />
  </svg>
);

export default QuestionSVG;
