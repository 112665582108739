import styled from 'styled-components';
import { headingThreeTextStyle } from 'src/mixins/text-styles';
import { colorProperty } from 'src/mixins/common-properties';
import { marginVariants } from './variants';

const HeadingThree = styled.h3`
  ${headingThreeTextStyle};
  color: ${colorProperty};
  ${marginVariants};
`;

export default HeadingThree;
