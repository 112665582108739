import React from 'react';
import LoadingWrapper from './styles/LoadingWrapper';
import LoadingIndicator from './styles/LoadingIndicator';
import SpinnerImg from './assets/spinner.png';

const spinnerObject = {
  src: SpinnerImg,
  alt: 'loading indicator',
};

const Spinner = () => (
  <LoadingWrapper>
    <LoadingIndicator src={spinnerObject.src} alt={spinnerObject.alt} />
  </LoadingWrapper>
);

export default Spinner;
