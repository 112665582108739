import { textStyle } from './utils';

export const bodyTextStyle = (props, customValues) =>
  textStyle({
    fontFamily: props.theme.fontFamilies.sansSerif,
    fontSize: `${props.theme.fontSizeNames.body.normal}px`,
    fontWeight: props.theme.fontWeights.sansSerif.normal,
    letterSpacing: props.theme.letterSpacings[5],
    lineHeight: `${props.theme.lineHeights[2]}px`,
    ...customValues,
  });

export const printBodyTextStyle = (props, customValues) =>
  bodyTextStyle(props, {
    fontSize: `${props.theme.fontSizeNames.print.body.normal}px`,
    letterSpacing: props.theme.letterSpacings[2],
    lineHeight: `${props.theme.lineHeights[0] + 1}px`,
    ...customValues,
  });

export const bodySerifTextStyle = (props, customValues) =>
  textStyle({
    fontFamily: props.theme.fontFamilies.serif,
    fontSize: `${props.theme.fontSizeNames.body.serif}px`,
    fontStyle: props.italic ? 'italic' : 'normal',
    fontWeight: props.bold
      ? props.theme.fontWeights.serif.bold
      : props.theme.fontWeights.serif.normal,
    letterSpacing: props.theme.letterSpacings[3],
    lineHeight: `${props.theme.lineHeights[2]}px`,
    ...customValues,
  });

export const printBodySerifTextStyle = (props, customValues) =>
  bodySerifTextStyle(props, {
    fontSize: `${props.theme.fontSizeNames.print.body.serif}px`,
    letterSpacing: props.theme.letterSpacings[0],
    lineHeight: `${props.theme.lineHeights[0] + 1}px`,
    ...customValues,
  });
