import styled from 'styled-components';
import { color } from 'styled-system';
import tag from 'clean-tag';

const CardPanel = styled(tag)`
  ${color};
  border: solid 1px #e9eef0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

CardPanel.propTypes = {
  ...color.propTypes,
};

CardPanel.defaultProps = {
  bg: 'white',
};

export default CardPanel;
