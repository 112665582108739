import React, { Children } from 'react';
import PropTypes from 'prop-types';

import * as CustomPropTypes from 'src/utils/custom-prop-types';

import ProportionalBox from '../styles/ProportionalBox';
import { RATIO_OPTIONS } from './constants';

const AspectRatioBox = ({ id, className, children, ratio }) => (
  <ProportionalBox id={id} className={className} ratio={ratio}>
    {Children.only(children)}
  </ProportionalBox>
);

AspectRatioBox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  /**
   * RATIO_OPTIONS:
   * highdefinition
   * square (default)
   * standard
   * widescreen
   * <number>:<number>
   */
  ratio: PropTypes.oneOfType([
    PropTypes.oneOf(RATIO_OPTIONS),
    CustomPropTypes.matches(/\d{1,2}:\d{1,2}/),
  ]),
};

AspectRatioBox.defaultProps = {
  id: null,
  className: null,
  ratio: RATIO_OPTIONS[1], // square
};

AspectRatioBox.displayName = 'AspectRatioBox';

export default AspectRatioBox;
