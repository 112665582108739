import React from 'react';
import PropTypes from 'prop-types';
import { COLOR_SCHEME_OPTIONS, DIRECTION_OPTIONS } from './constants';
import TooltipWrapper from './styles/TooltipWrapper';
import TooltipContent from './styles/TooltipContent';

const Tooltip = ({
  id,
  className,
  colorScheme,
  direction,
  message,
  children,
  maxWidth,
  offset,
  textAlign,
}) => (
  <TooltipWrapper id={id} className={className}>
    {children}
    <TooltipContent
      colorScheme={colorScheme}
      direction={direction}
      maxWidth={maxWidth}
      role="tooltip"
      offset={offset}
      textAlign={textAlign}
    >
      {message}
    </TooltipContent>
  </TooltipWrapper>
);

Tooltip.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  /**
   * COLOR_SCHEME_OPTIONS:
   * blackngray
   * blacknwhite
   */
  colorScheme: PropTypes.oneOf(COLOR_SCHEME_OPTIONS),
  /**
   * DIRECTION_OPTIONS:
   * top
   * right
   * bottom
   * left
   */
  direction: PropTypes.oneOf(DIRECTION_OPTIONS),
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  offset: PropTypes.number,
  textAlign: PropTypes.string,
};

Tooltip.defaultProps = {
  id: null,
  className: null,
  colorScheme: 'blackngray',
  direction: 'top',
  maxWidth: null,
  offset: 0,
  textAlign: 'center',
};

export default Tooltip;
