import styled from 'styled-components';
import tag from 'clean-tag';
import { color, space } from 'styled-system';

const GeneralLayoutContainer = styled(tag)`
  ${color};
  ${space};
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    overflow-y: auto;
  }
`;

GeneralLayoutContainer.defaultProps = {
  bg: 'gray.100',
  px: [3, 7],
};

export default GeneralLayoutContainer;
